import * as React from 'react';
// tslint:disable-next-line: no-var-requires
const checkmarkIcon = require('v2/svg/checkmark.svg');

export interface Props {
    id: string;
    name: string;
    value: string;
    checked: boolean;
    disabled: boolean;
    onChange: (checked: boolean) => void;
}

export function InputCheck(props: Props): JSX.Element {
    const {
        id,
        name,
        value,
        checked,
        disabled,
        onChange
    } = props;
    function onChangeCallback(e: React.SyntheticEvent<HTMLInputElement>): void {
        onChange(e.currentTarget.checked);
    }
    return (
        <span className={ `nshift-input-check ${checked ? 'nshift-checked' : ''} ${disabled ? 'nshift-disabled' : 'nshift-enabled'}` }>
            <input
                type="checkbox"
                id={ id }
                name={ name }
                value={ value }
                checked={ checked }
                disabled={ disabled }
                className="nshift-input-check-control"
                onChange={ onChangeCallback }
            />
            <span className="nshift-input-check-overlay1">
                <span
                    className="nshift-input-check-overlay2"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: checkmarkIcon }}
                />
            </span>
        </span>
    );
}
