import * as React from 'react';
import * as Model from '../model';
import Field from './field';

export interface AddonProps {
    option?: Model.Option;
    addon: Model.Addon;
    addonValue?: Model.AddonValue;
    disabled: boolean;
    theme: string;
    validationResult?: Model.ValidationAddonResult;
    onAddonValueChange: (addon: Model.Addon, value: Model.AddonValue) => void;
}

export default class Addon extends React.Component<AddonProps, {}> {

    public render() {
        const option = this.props.option || { id: 'global' };
        const addon = this.props.addon;
        let selected = false;
        let mandatory = false;
        if(this.props.addonValue) {
            selected = this.props.addonValue.selected;
        }
        mandatory = !!this.props.addon.mandatory;
        let name = option.id + '$' + addon.id;
        let type = 'checkbox';
        if(addon.oneOf && (addon.oneOf.length > 0)) {
            name = option.id + '$' + addon.oneOf.join('$$');
            type = 'radio';
        }
        return (
            <div className="unifaun-checkout-addon-container">
                <div className="unifaun-checkout-addon">
                    <label className="unifaun-checkout-addon-label">
                        <input
                            type={ type }
                            id={ option.id + '$' + addon.id }
                            name={ name }
                            className="unifaun-checkout-addon-checkbox unifaun-checkout-valign"
                            value={ addon.id }
                            checked={ selected }
                            disabled={ mandatory || this.props.disabled }
                            onChange={ this.onChange }/>
                        <span className='unifaun-checkout-custom-checkbox'/>
                        <span className="unifaun-checkout-addon-description unifaun-checkout-valign">{ addon.description }</span>
                        <span className="unifaun-checkout-addon-price unifaun-checkout-valign">{ addon.priceDescription }</span>
                        { this.props.theme !== 'unifaun-mp' && this.renderError() }
                    </label>
                </div>
                { this.props.theme !== 'unifaun-mp' &&
                    this.renderFields(selected)
                }
                { this.props.theme === 'unifaun-mp' &&
                    <div className="unifaun-checkout-fields-container">
                        { this.renderFields(selected) }
                        { this.renderError() }
                    </div>
                }           
            </div>
        );
    }

    private renderError() {
        if(this.props.validationResult && this.props.validationResult.message) {
            return (<span className="unifaun-checkout-addon-error unifaun-checkout-valign">{ this.props.validationResult.message }</span>);
        } else {
            return null;
        }
    }
    private renderFields(selected: boolean) {
        if(!this.props.addon.fields) {
            return null;
        }
        let validationResults: Model.ValidationFieldResultMap | undefined = undefined;
        if(this.props.validationResult) {
            validationResults = this.props.validationResult.fields;
        }
        const addonValue = this.props.addonValue;
        return this.props.addon.fields.map((field) => {
            let value = undefined;
            if(addonValue && addonValue.fields) {
                value = addonValue.fields[field.id];
            }
            let validationResult: Model.ValidationFieldResult | undefined = undefined;
            if(validationResults) {
                validationResult = validationResults[field.id];
            }
            return (
                <Field
                    key={ field.id + '$AddonField' }
                    option={ this.props.option }
                    field={ field }
                    value={ value }
                    active={ selected }
                    disabled={ this.props.disabled || !selected }
                    validationResult={ validationResult }
                    onFieldValueChange={ this.onFieldValueChange }
                    theme={ this.props.theme}/>
            );
        });
    }

    private onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const addon = this.props.addon;
        const addonValue = this.props.addonValue;
        const value: Model.AddonValue = Model.buildAddonValue(addon, addonValue, {
            selected: event.currentTarget.checked,
        });
        this.props.onAddonValueChange(addon, value);
    }

    private onFieldValueChange = (fieldValue: Model.FieldValue) => {
        const addon = this.props.addon;
        let addonValue = this.props.addonValue;
        let fieldValues = {};
        if(addonValue && addonValue.fields) {
            fieldValues = {
                ...addonValue.fields,
                [fieldValue.id]: fieldValue
            };
        }
        addonValue = Model.buildAddonValue(addon, addonValue, {
            fields: fieldValues
        });
        this.props.onAddonValueChange(addon, addonValue);
    }
}
