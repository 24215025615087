import FocusTrap = require('focus-trap-react');
import * as React from 'react';
import { Fragment, useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { OpeningHourSpecialDay, OpeningHourWeekday } from '../../v1/model';
import translatedTexts from '../../v1/translated-texts';
import { AgentWithOptionId } from '../utils/internal-types';
import { useResizeManager } from '../utils/resize-manager';
import { InputRadio } from './input-radio';
import { PickupPointsMap } from './pickup-points-map';

// tslint:disable-next-line: no-var-requires
const timeIcon = require('v2/svg/time.svg');
// tslint:disable-next-line: no-var-requires
const closeIcon = require('v2/svg/close.svg');
// tslint:disable-next-line: no-var-requires
const pickupPointIcon = require('v2/svg/pickuppoint.svg');
// tslint:disable-next-line: no-var-requires
const upIcon = require('v2/svg/up.svg');
// tslint:disable-next-line: no-var-requires
const downIcon = require('v2/svg/down.svg');
// tslint:disable-next-line: no-var-requires
const locationIcon = require('v2/svg/location.svg');

const NO_PICKUP_POINTS: AgentWithOptionId[] = [];

export interface Props {
    popupContainerElement: Element;
    pickupPoints: AgentWithOptionId[];
    selectedOptionId: string;
    selectedPickupPointId: string | undefined;
    disabled: boolean;
    onSelect: (pickupPoint: AgentWithOptionId) => void;
    onCancel: () => void;
}

export function PickupPointsPopup(props: Props): JSX.Element {
    const {
        popupContainerElement,
        pickupPoints,
        selectedOptionId,
        selectedPickupPointId,
        disabled,
        onSelect,
        onCancel
    } = props;
    const [pickupPointId, setPickupPointId] = useState<string>(selectedPickupPointId ?? (pickupPoints.length > 0 ? pickupPoints[0].id : ''));
    const onPickupPointChangeCallback = useCallback((pickupPoint: AgentWithOptionId) => {
        setPickupPointId(pickupPoint.id);
    }, [setPickupPointId]);
    const onPickupPointChangeIdCallback = useCallback((id: string) => {
        setPickupPointId(id);
    }, [setPickupPointId]);
    const onSelectCallback = useCallback(() => {
        const pickupPoint = pickupPoints.find((item) => item.id === pickupPointId);
        if(pickupPoint) {
            onSelect(pickupPoint);
        } else {
            onCancel();
        }
    }, [pickupPointId, pickupPoints, onSelect]);
    const onPickupPointFocusCallback = useCallback((id: string) => {
        let element = document.getElementById(id);
        while(element) {
            if(element.tagName === 'LI') {
                element.scrollIntoView({
                    block: 'nearest'
                });
                break;
            }
            element = element.parentElement;
        }
    }, []);
    function onCancelCallback(e: React.SyntheticEvent<unknown>): void {
        e.preventDefault();
        onCancel();
    }
    function onDropClickCallback(e: React.SyntheticEvent<unknown>): void {
        e.stopPropagation();
    }
    return createPortal(
        <FocusTrap focusTrapOptions={ {
            initialFocus: `#CheckoutWidgetPicker${selectedPickupPointId ?? (pickupPoints.length > 0 ? pickupPoints[0].id : '')}`
        } }>
            <div className="nshift-pickup-points-overlay" onMouseDown={ onCancelCallback }>
                <div className="nshift-pickup-points-popup" onMouseDown={ onDropClickCallback }>
                    <div className="nshift-pickup-points-popup-title">
                        <span
                            className="nshift-pickup-point-icon"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={ { __html: pickupPointIcon }}
                        />
                        <span>{ translatedTexts.texts.agentSelectLabel }</span>
                        <button
                            type="button"
                            onClick={ onCancelCallback }
                        >
                            <span
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={ { __html: closeIcon }}
                            />
                        </button>
                    </div>
                    <PickupPointsMap
                        primaryPickupPoints={ pickupPoints }
                        secondaryPickupPoints={ NO_PICKUP_POINTS }
                        selectedOptionId={ selectedOptionId }
                        selectedPickupPointId={ pickupPointId }
                        disabled={ disabled }
                        onSelect={ onPickupPointChangeCallback }
                    />
                    <div className="nshift-list-column">
                        <div className="nshift-list-container">
                            <ul>
                                { pickupPoints.map((pickupPoint) => (
                                    <li key={ pickupPoint.id } className={ `${pickupPointId === pickupPoint.id ? 'nshift-active' : ''}`}>
                                        <label id={ `CheckoutWidgetPicker${pickupPoint.id}` } className="nshift-pickup-point-list-label">
                                            <div className="nshift-pickup-point-list-item-header">
                                                <InputRadio
                                                    id={ `CheckoutWidgetPicker${pickupPoint.id}` }
                                                    name="CheckoutWidgetPicker"
                                                    value={ pickupPoint.id }
                                                    checked={ pickupPointId === pickupPoint.id }
                                                    disabled={ disabled }
                                                    onChange={ onPickupPointChangeIdCallback }
                                                    onFocus={ onPickupPointFocusCallback }
                                                />
                                                <span className="nshift-pickup-point-list-text">
                                                    <span className="nshift-pickup-point-list-name">{ pickupPoint.name }</span>
                                                </span>
                                            </div>
                                            <div className="nshift-pickup-point-list-item-address">
                                                <span
                                                    className="nshift-icon"
                                                    // eslint-disable-next-line react/no-danger
                                                    dangerouslySetInnerHTML={ { __html: locationIcon }}
                                                />
                                                <span className="nshift-pickup-point-list-address">{ pickupPoint.address1 }, { pickupPoint.city }</span>
                                            </div>
                                            { ((pickupPoint.openingHourWeekdays && (pickupPoint.openingHourWeekdays.length > 0))
                                                || (pickupPoint.openingHourSpecialDays && (pickupPoint.openingHourSpecialDays.length > 0))) ? (
                                                <OpenHoursPanel
                                                    weekDays={ pickupPoint.openingHourWeekdays }
                                                    specialDays={ pickupPoint.openingHourSpecialDays }
                                                />
                                            ) : null }
                                        </label>
                                    </li>
                                )) }
                            </ul>
                        </div>
                        <div className="nshift-list-actions">
                            <button
                                type="button"
                                onClick={ onSelectCallback }
                            >
                                { translatedTexts.texts.chooseLabel }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>,
        popupContainerElement
    );
}

interface OpenHoursPanelProps {
    weekDays: OpeningHourWeekday[] | undefined;
    specialDays: OpeningHourSpecialDay[] | undefined;
}

function OpenHoursPanel(props: OpenHoursPanelProps): JSX.Element {
    const {
        weekDays,
        specialDays,
    } = props;
    const [open, setOpen] = useState(false);
    function onToggleCallback(e: React.SyntheticEvent<unknown>): void {
        e.preventDefault();
        setOpen(!open);
    }
    const resizeManager = useResizeManager();
    const foldingElementRef = useRef<HTMLDivElement>(null);
    const [foldingHeight, setFoldingHeight] = useState<number>(0);
    useEffect(() => {
        if(foldingElementRef.current) {
            return resizeManager.add(foldingElementRef.current, (size) => {
                setFoldingHeight(size.h);
            });
        }
        return undefined;
    }, []);
    const openHours: (OpeningHourWeekday | OpeningHourSpecialDay)[] = useMemo(() => {
        let result: (OpeningHourWeekday | OpeningHourSpecialDay)[] = [];
        if(weekDays) {
            result = result.concat(weekDays);
        }
        if(specialDays) {
            result = result.concat(specialDays);
        }
        return result;
    }, [weekDays, specialDays]);
    return (
        <>
            <button
                type="button"
                className="nshift-open-hours-toggle"
                onClick={ onToggleCallback }
            >
                <span
                    className="nshift-icon"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={ { __html: timeIcon }}
                />
                <span className="nshift-text">{ translatedTexts.texts.openingHours }</span>
                { open ? (
                    <span
                        className="nshift-icon"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={ { __html: upIcon }}
                    />
                ) : (
                    <span
                        className="nshift-icon"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={ { __html: downIcon }}
                    />
                ) }
            </button>
            <div className="nshift-folding-block" style={ { height: `${open ? foldingHeight : '0'}px` } }>
                <div ref={ foldingElementRef }>
                    <table className="nshift-pickup-point-open-hours">
                        <tbody>
                            { openHours.map((openHour, dayIndex) => (
                                <tr key={ `open${dayIndex}` }>
                                    { isSpecialDay(openHour) ? (
                                        <td className="nshift-open-hour-description">{ openHour.date }</td>
                                    ) : (
                                        <td className="nshift-open-hour-description">{ openHour.description }</td>
                                    )}
                                    { openHour.closed ? (
                                        <td className="nshift-open-hour-closed">{ translatedTexts.texts.closed }</td>
                                    ) : (
                                        <td className="nshift-open-hour-hours">
                                            { openHour.hours.map((hour, index) => (
                                                <Fragment key={ `hour${index}` }>
                                                    {index > 0 ? (<br />) : null }
                                                    { hour.start }
                                                    { ' - ' }
                                                    { hour.stop }
                                                </Fragment>
                                            )) }
                                        </td>
                                    ) }
                                </tr>
                            )) }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

function isSpecialDay(day: OpeningHourWeekday | OpeningHourSpecialDay): day is OpeningHourSpecialDay {
    return !!(day as OpeningHourSpecialDay).date;
}
