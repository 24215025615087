import * as React from 'react';
import { createPortal } from 'react-dom';
import { useState, useEffect, Fragment } from 'react';
import { usePopper } from 'react-popper';
import { Certification, Feature } from '../../v1/model';
import { usePopupContainer } from 'v2/utils/popup-container';
import translatedTexts from 'v1/translated-texts';

const ALLOWED_COLORS = ['black', 'blue', 'red', 'green', 'yellow'];

export interface Props {
    badge: Feature | Certification;
    iconsBaseUrl: string;
}

export function BadgeItem(props: Props): JSX.Element {
    const {
        badge,
        iconsBaseUrl,
    } = props;
    const popupContainerElement = usePopupContainer();
    const [badgeElem, setBadgeElem] = useState<HTMLAnchorElement | HTMLSpanElement | null>(null);
    const [toolTipElem, setToolTipElem] = useState<HTMLDivElement | null>(null);
    const [arrowElem, setArrowElem] = useState<HTMLDivElement | null>(null);
    const { styles, attributes } = usePopper(badgeElem, toolTipElem, {
        placement: 'bottom',
        modifiers: [{
            name: 'flip'
        }, {
            name: 'offset',
            options: {
                offset: [0, 5]
            }
        }, {
            name: 'arrow',
            options: {
                element: arrowElem
            }
        }]
    });
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    function onTogglePopupCallback(e: React.SyntheticEvent<unknown>): void {
        e.preventDefault();
        e.stopPropagation();
        setIsPopupVisible(!isPopupVisible);
    }
    function onClickOutsideCallback(): void {
        setIsPopupVisible(false);
    }
    useEffect(() => {
        if(isPopupVisible) {
            document.addEventListener('click', onClickOutsideCallback);
            return () => {
                document.removeEventListener('click', onClickOutsideCallback);
            };
        }
        return undefined;
    }, [isPopupVisible]);
    const color = badge.color && ALLOWED_COLORS.includes(badge.color) ? badge.color : ALLOWED_COLORS[0];
    return (
        <div>
            { badge.description ? (
                <button
                    type="button"
                    ref={ setBadgeElem }
                    className={ `nshift-badge nshift-${color} nshift-badge-${badge.iconId}` }
                    onClick={ onTogglePopupCallback }
                >
                    { buildCore(badge, iconsBaseUrl) }
                </button>
            ) : (
                <span
                    ref={ setBadgeElem }
                    className={ `nshift-badge nshift-${color} nshift-badge-${badge.iconId}` }
                >
                    { buildCore(badge, iconsBaseUrl) }
                </span>
            ) }
            { badge.description && isPopupVisible && popupContainerElement ? createPortal(
                <div
                    ref={ setToolTipElem }
                    className="nshift-badge-tooltip"
                    style={ styles.popper }
                    { ...attributes.popper }
                >
                    { badge.description.split(/[\n\r]+/g).map((line, index) => (
                        <Fragment key={ `line${index}` }>
                            { index > 0 ? <br /> : null }
                            { line }
                        </Fragment>
                    )) }
                    { badge.url ? (
                        <div className="nshift-badge-tooltip-link">
                            <a href={ badge.url } rel="noreferrer" target="_blank">{ translatedTexts.texts.agentInfoLabelShort }</a>
                        </div>
                    ) : null }
                    <div
                        ref={ setArrowElem }
                        className="nshift-badge-tooltip-arrow"
                        style={ styles.arrow }
                        { ...attributes.arrow }
                    />
                </div>,
                popupContainerElement
            ) : null }
        </div>
    );
}

function buildCore(badge: Feature | Certification, iconsBaseUrl: string): JSX.Element {
    return (
        <>
            { badge.iconId ? (
                <img
                    className="nshift-badge-icon"
                    src={ `${iconsBaseUrl}badge_${badge.iconId}.svg` }
                    alt="badge icon"
                />
            ) : null }
            <span
                className="nshift-badge-text"
            >
                { badge.name }
            </span>
        </>
    );
}
