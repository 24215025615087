export function debounce(fn: (...args: any[]) => void, ms: number) {
    let currentTimeoutId: any;

    return (...args: any[]) => {
        if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
        }

        currentTimeoutId = setTimeout(() => {
            currentTimeoutId = undefined;
            fn(...args);
        }, ms);
    };
}
