import * as React from 'react';

export interface LogoImageProps {
    url: string;
    className?: string;
}

export interface LogoImageState {
    loaded: boolean;
}

export default class LogoImage extends React.Component<LogoImageProps, LogoImageState> {

    constructor(props: LogoImageProps, context?: any) {
        super(props, context);
        this.state = {
            loaded: false
        };
    }

    public render() {
        let styles: React.CSSProperties | undefined = undefined;
        if(!this.state.loaded) {
            styles = {
                display: 'none'
            };
        }
        return (
            <img src={ this.props.url } onLoad={ this.onLoad } style={ styles } className={ this.props.className }/>
        );
    }

    private onLoad = () => {
        this.setState({
            loaded: true
        });
    }
}
