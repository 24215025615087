import * as React from 'react';
import { contactSvgURL, emailSvgURL, doorCodeSvgURL, phoneSvgURL } from './svgs';

export interface TextInputProps {
    id: string;
    caption: string;
    value: string;
    disabled?: boolean;
    message?: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    theme: string;
}

export interface TextInputState {
    focused?: boolean;
}

interface SvgStyle {
    icon: string;
    style?: {marginTop?: string; marginLeft?: string};
} 

const inputIdTypes: {[id: string]: SvgStyle} = {
    'email': {icon: emailSvgURL, style: {marginTop: "4px"}},
    'sms': {icon: phoneSvgURL, style: { marginLeft: "3px"}},
    'SMS_NUMBER': {icon: phoneSvgURL, style: { marginLeft: "3px"}},
    'EMAIL_ADDR': {icon: emailSvgURL, style: {marginTop: "4px"}},
    'FCRECEIVERSMS': {icon: phoneSvgURL, style: { marginLeft: "3px"}},
    'FCRECEIVERPHONE': {icon: phoneSvgURL, style: { marginLeft: "3px"}},
    'FCRECEIVEREMAIL': {icon: emailSvgURL, style: {marginTop: "4px"}},
    'FCRECEIVERCONTACT': {icon: contactSvgURL, style: {marginLeft: "-3px"}},
    'FCRECEIVERDOORCODE': {icon: doorCodeSvgURL}
}

export default class TextInput extends React.Component<TextInputProps, TextInputState> {

    private inputElement: HTMLElement | null;

    constructor(props: TextInputProps, context?: any) {
        super(props, context);
        this.state = {
            focused: false
        };
    }

    public render() {
        let className = '';
        if(this.props.value || this.state.focused) {
            className = className + ' unifaun-checkout-active';
        }
        if(this.props.disabled) {
            className = className + ' unifaun-checkout-disabled';
        }
        const message = this.props.message;
        if(message) {
            className = className + ' unifaun-checkout-invalid';
        }
        if (this.props.theme === "unifaun-mp" && Object.keys(inputIdTypes).filter(id => this.props.id.endsWith(id)).length > 0) {
            return (
                <span className={ 'unifaun-checkout-text-input' + className } onMouseDown={ this.onClick }>
                    <label id={ this.props.id + '$Lbl' } htmlFor={ this.props.id } className="unifaun-checkout-text-input-label-icon" onMouseDown={ this.onClick }>
                        <img src={this.determineIcon(this.props.id)} style={this.determineIconStyle(this.props.id)}/>
                    </label>
                    <span id={ this.props.id + '$Msg' } className="unifaun-checkout-text-input-label-message">{ message }</span>
                    <input
                        ref={ (elem) => { this.inputElement = elem; } }
                        id={ this.props.id }
                        className="unifaun-checkout-text-input-input"
                        placeholder={ this.props.caption }
                        value={ this.props.value || '' }
                        disabled={ this.props.disabled }
                        aria-labelledby={ this.props.id + '$Lbl ' + this.props.id + '$Msg' }
                        onBlur={this.props.onBlur}
                        onChange={ this.onChange }/>
                </span>
            );
        } else {
            return (
                <span className={ 'unifaun-checkout-text-input' + className } onFocus={ this.onFocus } onBlur={ this.onBlur } onMouseDown={ this.onClick }>
                    <label id={ this.props.id + '$Lbl' } htmlFor={ this.props.id } className="unifaun-checkout-text-input-label" onMouseDown={ this.onClick }>
                        { this.props.caption }
                    </label>
                    <span id={ this.props.id + '$Msg' } className="unifaun-checkout-text-input-label-message">{ message }</span>
                    <input
                        ref={ (elem) => { this.inputElement = elem; } }
                        id={ this.props.id }
                        className="unifaun-checkout-text-input-input"
                        value={ this.props.value || '' }
                        disabled={ this.props.disabled }
                        aria-labelledby={ this.props.id + '$Lbl ' + this.props.id + '$Msg' }
                        onBlur={this.props.onBlur}
                        onChange={ this.onChange }/>
                </span>
            );
        }
    }

    private determineIcon = (fieldId: string) => {
        for (const id in inputIdTypes) {
            if (fieldId.endsWith(id)) {
                return inputIdTypes[id].icon;
            }
        }
    } 

    private determineIconStyle = (fieldId: string) => {
        for (const id in inputIdTypes) {
            if (fieldId.endsWith(id)) {
                return inputIdTypes[id].style;
            }
        }
    }

    private onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.props.onChange(event.currentTarget.value);
    }

    private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ focused: true });
    }

    private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ focused: false });
    }

    private onClick = (event: React.MouseEvent<any>) => {
        if(this.inputElement) {
            this.inputElement.focus();
        }
    }
}
