import { createContext, useContext } from 'react';

export const PopupContainerContext = createContext<HTMLElement | undefined>(undefined);

export function usePopupContainer(): HTMLElement | undefined {
    return useContext(PopupContainerContext);
}

const PopupContainerProvider = PopupContainerContext.Provider;
export default PopupContainerProvider;
