export const FIELD_TYPE_EMAIL = 'EMAIL';
export const FIELD_TYPE_PHONE = 'PHONE';
export const FIELD_TYPE_TEXT = 'TEXT';
export const FIELD_TYPE_NUMBER = 'NUMBER';
export const FIELD_TYPE_DIGITS = 'DIGITS';

export const FIELD_TYPES = [FIELD_TYPE_EMAIL, FIELD_TYPE_PHONE, FIELD_TYPE_TEXT, FIELD_TYPE_NUMBER, FIELD_TYPE_DIGITS];

export const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PHONE_REGEXP = /^\+?(\([0-9]+\))?[ \-0-9]+$/;
export const NUMBER_REGEXP = /^(\+|\-)?([0-9]+|[0-9]*(,|\.)[0-9]+)$/;
export const DIGITS_REGEXP = /^[ 0-9]+$/;

export interface FieldItem {
    id: string;
    description: string;
}

export interface FieldValue {
    id: string;
    value: string;
}

export interface FieldValueMap {
    [id: string]: FieldValue;
}

export interface AddonValue {
    id: string;
    selected: boolean;
    dependency?: string[];
    fields: FieldValueMap;
}

export interface AddonValueMap {
    [id: string]: AddonValue;
}

export interface AddonDisableMap {
    [id: string]: boolean;
}

export interface Field {
    id: string;
    description: string;
    type: string;
    initialValue?: string;
    mandatory?: boolean;
    min?: number;
    max?: number;
    pattern?: string;
    items?: FieldItem[];
}

export interface Addon {
    id: string;
    description: string;
    selected?: boolean;
    mandatory?: boolean;
    priceDescription: string;
    price: number;
    oneOf?: string[];
    atLeastOneOf?: string[];
    exclude?: string[];
    dependency?: string[];
    fields?: Field[];
}

export interface OpeningHourWeekday {
    weekday: string;
    description?: string;
    hours: { start: string; stop: string; }[];
    closed: boolean;
}

export interface OpeningHourSpecialDay {
    date: string;
    hours: { start: string; stop: string; }[];
    closed: boolean;
}

export interface Agent {
    mapLatitude: number;
    mapLongitude: number;
    id: string;
    name: string;
    address1: string;
    address2: string;
    zipCode: string;
    city: string;
    state: string;
    additionalInfo: string;
    openingHourWeekdays?: OpeningHourWeekday[];
    openingHourSpecialDays?: OpeningHourSpecialDay[];
}

export interface Feature {
    id: string;
    name: string;
    description: string;
    color?: string;
    iconId?: string;
    url?: string;
}

export interface Certification {
    id: string;
    name: string;
    description: string;
    color?: string;
    iconId?: string;
    url?: string;
}

export interface Option {
    id: string;
    name: string;
    description1?: string;
    description2?: string;
    description3?: string;
    description4?: string;
    carrierId?: string;
    priceDescription: string;
    priceValue: number;
    taxRate?: number;
    subOptions?: Option[];
    addons?: Addon[];
    fields?: Field[];
    agents?: Agent[];
    features?: Feature[];
    certifications?: Certification[];
    noDefaultAgent?: boolean;
}

export interface Root {
    deliveryCheckoutId: string;
    fields?: Field[];
    addons?: Addon[];
    options: Option[];
}

export interface IdMap {
    [id: string]: string;
}

export interface FieldResult {
    id: string;
    value: string;
}

export interface AddonResult {
    id: string;
    price: number;
    fields: FieldResult[];
}

export interface Result {
    valid: boolean;
    deliveryCheckoutId: string;
    selectedOptionId: string;
    optionIds: string[];
    price: number;
    taxRate: number;
    agent?: string;
    selectedAddons: AddonResult[];
    fields: FieldResult[];
}

export interface Configuration {
    language?: string;
    useIcons?: boolean;
    iconsInFront?: boolean;
    iconsBaseUrl?: string;
    installCSS?: boolean | string;
    narrowDisplay?: boolean;
    narrowBreakpointWidth?: number;
    ultraNarrowBreakpointWidth?: number;
    resultCallback?: (result: Result) => void;
    hideAgentInfo?: boolean;
    enableMap?: boolean;
    version?: number;
    popupContainerElement?: Element;
}

export interface ValidationFieldResult {
    message: string;
}

export interface ValidationFieldResultMap {
    [id: string]: ValidationFieldResult;
}

export interface ValidationAddonResult {
    fields: ValidationFieldResultMap;
    count: number;
    message?: string;
}

export interface ValidationAddonResultMap {
    [id: string]: ValidationAddonResult;
}

export interface ValidationAgentResultMap {
    [id: string]: ValidationFieldResult;
}

export interface ValidationResult {
    addons: ValidationAddonResultMap;
    fields: ValidationFieldResultMap;
    customFields: ValidationFieldResultMap;
    agents: ValidationAgentResultMap;
    count: number;
}

export interface Coordinate {
    lat: number;
    lng: number;
}

export function levelKey(level: number): string {
    let result = '';
    for(let n = 0; n <= level; n++) {
        result += '$' + n;
    }
    return result;
}

export function buildAddonValue(addon: Addon, addonValue: AddonValue | undefined, change: Partial<AddonValue>): AddonValue {
    let result: Partial<AddonValue>;
    if(!addonValue) {
        result = {
            id: addon.id,
            selected: addon.mandatory || addon.selected || false,
            fields: {},
            dependency: addon.dependency,
            ...change
        };
    } else {
        result = {
            ...addonValue,
            ...change
        };
    }
    return <AddonValue>result;
}

export function buildFieldValue(field: Field, fieldValue: FieldValue | undefined, change: Partial<FieldValue>): FieldValue {
    let result: Partial<FieldValue>;
    if(!fieldValue) {
        result = {
            id: field.id,
            value: '',
            ...change
        };
    } else {
        result = {
            ...fieldValue,
            ...change
        };
    }
    return <FieldValue>result;
}

export function isNumeric(value: string): boolean {
    const num = parseFloat(value);
    return !isNaN(num) && isFinite(num);
}
