/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import {
    Addon,
    Field,
    Option,
    AddonValue,
    FieldValue,
    AddonValueMap,
    FieldValueMap,
    ValidationResult
} from '../../v1/model';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { useResizeManager } from '../utils/resize-manager';
import translatedTexts from '../../v1/translated-texts';
import {
    AgentWithOptionId,
    ListItem,
} from '../utils/internal-types';
import { AddonItem } from './addon-item';
import { FieldItem } from './field-item';
import { InputDropDown } from './input-dropdown';
import { InputRadio } from './input-radio';
import { LogoImage } from './logo-image';
import { PickupPointsPopup } from './pickup-points-popup';
import { usePopupContainer } from 'v2/utils/popup-container';
import { BadgeItem } from './badge-item';

// tslint:disable-next-line: no-var-requires
const rightIcon = require('v2/svg/right.svg');

const NO_PICKUP_POINTS: AgentWithOptionId[] = [];

export interface Props {
    sessionId: string;
    option: Option;
    addonValues: AddonValueMap;
    fieldValues: FieldValueMap;
    validationResult: ValidationResult;
    agentId?: string;
    level: number;
    parentId: string;
    selected: boolean;
    disabled: boolean;
    mapEnabled: boolean;
    iconsBaseUrl: string;
    onChange: (level: number, parentId: string, option: Option) => void;
    onChangePickupPoint: (pickupPoint: AgentWithOptionId) => void;
    onChangeAddon: (addon: Addon, addonState: AddonValue) => void;
    onChangeField: (field: Field, fieldState: FieldValue) => void;
}

export function OptionItem(props: Props): JSX.Element {
    const {
        sessionId,
        option,
        addonValues,
        fieldValues,
        validationResult,
        agentId,
        level,
        parentId,
        selected,
        disabled,
        mapEnabled,
        iconsBaseUrl,
        onChange,
        onChangePickupPoint,
        onChangeAddon,
        onChangeField,
    } = props;
    const popupContainerElement = usePopupContainer();
    const pickupPointItems = (option.agents ?? []).map((pickupPoint) => ({
        // eslint-disable-next-line max-len
        name: `${pickupPoint.name}///${pickupPoint.address1 ?? pickupPoint.address2 ?? ''}${pickupPoint.address1 || pickupPoint.address2 ? ', ' : ''}${pickupPoint.city ?? ''}`,
        value: pickupPoint.id,
    }));
    function onBlockClickCallback(e: React.SyntheticEvent<HTMLElement>): void {
        e.preventDefault();
    }
    const onChangeCallback = useCallback(() => {
        onChange(level, parentId, option);
    }, [level, parentId, option]);
    const onChangePickupPointCallback = useCallback((pickupPointId: string) => {
        const pickupPoint = (option.agents ?? []).find((item) => item.id === pickupPointId);
        if(pickupPoint) {
            onChangePickupPoint({ ...pickupPoint, optionId: option.id });
        }
    }, [option]);
    const [showPickupPointPicker, setShowPickupPointPicker] = useState(false);
    const onPickPickupPointCallback = useCallback((e: React.SyntheticEvent<unknown>) => {
        e.preventDefault();
        onChangeCallback();
        setShowPickupPointPicker(true);
    }, [onChangeCallback]);
    const onSelectPickupPointsPopupCallback = useCallback((pickupPoint: AgentWithOptionId) => {
        setShowPickupPointPicker(false);
        onChangePickupPoint({ ...pickupPoint, optionId: option.id });
    }, [setShowPickupPointPicker, option]);
    const onClosePickupPointsPopupCallback = useCallback(() => {
        setShowPickupPointPicker(false);
    }, [setShowPickupPointPicker]);
    const resizeManager = useResizeManager();
    const foldingElementRef = useRef<HTMLDivElement>(null);
    const [foldingHeight, setFoldingHeight] = useState<number>(0);
    useEffect(() => {
        if(foldingElementRef.current) {
            return resizeManager.add(foldingElementRef.current, (size) => {
                setFoldingHeight(size.h);
            });
        }
        return undefined;
    }, []);

    const primaryPickupPoints = useMemo(
        () => option.agents?.map((pup) => ({ ...pup, optionId: option.id })) ?? NO_PICKUP_POINTS,
        [option.agents]
    );

    const noGeoLocations = primaryPickupPoints.every((pup) => (pup.mapLatitude === null) || (pup.mapLatitude === undefined) || Number.isNaN(pup.mapLatitude)
        || (pup.mapLongitude === null) || (pup.mapLongitude === undefined) || Number.isNaN(pup.mapLongitude));

    const pickupPointErrorMessage = validationResult.agents[option.id]?.message;

    return (
        <li className={ `nshift-option ${disabled ? 'nshift-disabled' : 'nshift-enabled'} ${selected ? 'nshift-selected' : ''}` }>
            <label
                className="nshift-option-label nshift-outer-block"
                htmlFor={ `CheckoutWidgetOption${sessionId}${option.id}` }
                onMouseDown={ onBlockClickCallback }
            >
                <div className="nshift-separated-block">
                    <div className="nshift-option-header">
                        <InputRadio
                            id={ `CheckoutWidgetOption${sessionId}${option.id}` }
                            name={ `CheckoutWidgetOption${sessionId}` }
                            value={ option.id }
                            checked={ selected }
                            disabled={ disabled }
                            onChange={ onChangeCallback }
                        />
                        <div className="nshift-option-header-col1">
                            <div className="nshift-option-title">{ option.name }</div>
                            <div className="nshift-option-text1">{ option.description1 ?? '' }</div>
                        </div>
                        <div className="nshift-option-header-col2">
                            <LogoImage url={ iconsBaseUrl + (option.carrierId ?? '').toLowerCase() + '.png' } logoId={ option.carrierId ?? '' } />
                            <div className="nshift-option-price1">{ option.priceDescription }</div>
                        </div>
                    </div>
                    { (option.features && option.features.length > 0) || (option.certifications && option.certifications.length > 0) ? (
                        <div className="nshift-option-badges">
                            { (option.certifications ?? []).concat(option.features ?? []).map((feature) => (
                                <BadgeItem
                                    badge={ feature }
                                    iconsBaseUrl={ iconsBaseUrl }
                                />
                            )) }
                        </div>
                    ) : null }
                </div>
            </label>
            <div className="nshift-folding-block" style={ { height: `${selected ? foldingHeight : '0'}px` } }>
                <div ref={ foldingElementRef }>
                    { (option.description2 && option.description2.trim().length > 0) || (option.description3 && option.description3.trim().length > 0)
                        || (option.description4 && option.description4.trim().length > 0) ? (
                        <div className="nshift-option-pickup-points nshift-separated-block nshift-outer-block">
                            { option.description2 && option.description2.trim().length > 0 ? (
                                <div className="nshift-option-text2">{ option.description2 ?? '' }</div>
                            ) : null }
                            { option.description3 && option.description3.trim().length > 0 ? (
                                <div className="nshift-option-text3">{ option.description3 }</div>
                            ) : null }
                            { option.description4 && option.description4.trim().length > 0 ? (
                                <div className="nshift-option-text4">{ option.description4 }</div>
                            ) : null }
                        </div>
                    ) : null }
                    { pickupPointItems.length > 0 ? (
                        <div className="nshift-option-pickup-points nshift-separated-block nshift-outer-block">
                            { mapEnabled && !noGeoLocations && popupContainerElement ? (
                                <button
                                    type="button"
                                    className="nshift-option-pickup-point-picker"
                                    disabled={ disabled }
                                    onClick={ onPickPickupPointCallback }
                                >
                                    <span className="nshift-text">
                                        { agentId
                                            ? onPickupPointFormat(pickupPointItems.find((item) => item.value === agentId) ?? { name: '', value: '' })
                                            : translatedTexts.texts.chooseLabel }
                                    </span>
                                    <span
                                        className="nshift-icon"
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={ { __html: rightIcon }}
                                    />
                                </button>
                            ) : (
                                <InputDropDown
                                    id={ `CheckoutWidgetPickupPoints${sessionId}${option.id}` }
                                    name={ `CheckoutWidgetPickupPoints${sessionId}${option.id}` }
                                    value={ agentId ?? '' }
                                    clearable={ option.noDefaultAgent === true }
                                    disabled={ !selected || disabled }
                                    items={ pickupPointItems }
                                    errorMessage={ pickupPointErrorMessage }
                                    onFormatItem={ onPickupPointFormat }
                                    onChange={ onChangePickupPointCallback }
                                />
                            ) }
                            { showPickupPointPicker && mapEnabled && !noGeoLocations && popupContainerElement ? (
                                <PickupPointsPopup
                                    pickupPoints={ primaryPickupPoints }
                                    selectedOptionId={ option.id }
                                    selectedPickupPointId={ agentId }
                                    popupContainerElement={ popupContainerElement }
                                    disabled={ disabled }
                                    onSelect={ onSelectPickupPointsPopupCallback }
                                    onCancel={ onClosePickupPointsPopupCallback }
                                />
                            ) : null }
                        </div>
                    ) : null }
                    { option.addons && (option.addons.length > 0) ? (
                        <div className="nshift-separated-block nshift-outer-block">
                            <ul className="nshift-option-addons">
                                { (option.addons ?? []).map((addon) => (
                                    <AddonItem
                                        key={ addon.id }
                                        sessionId={ sessionId }
                                        option={ option }
                                        addon={ addon }
                                        addonState={ addonValues[addon.id] }
                                        disabled={ disabled }
                                        validationResult={ validationResult.addons[addon.id] }
                                        onChange={ onChangeAddon }
                                    />
                                )) }
                            </ul>
                        </div>
                    ) : null }
                    { option.fields && (option.fields.length > 0) ? (
                        <div className="nshift-separated-block nshift-outer-block">
                            <div className="nshift-option-fields">
                                { (option.fields ?? []).map((field) => (
                                    <FieldItem
                                        key={ field.id }
                                        idPrefix={ `CheckoutWidgetOption${sessionId}${option.id}` }
                                        field={ field }
                                        fieldState={ fieldValues[field.id] }
                                        disabled={ disabled }
                                        validationResult={ validationResult.fields[field.id] }
                                        onChange={ onChangeField }
                                    />
                                )) }
                            </div>
                        </div>
                    ) : null }
                </div>
            </div>
            { /*
            <div className="nshift-separated-block nshift-outer-block">
                <div className="nshift-option-badges">
                    <span className="nshift-badge">Badge1</span>
                    <span className="nshift-badge">Badge2</span>
                </div>
            </div>
            */ }
        </li>
    );
}

function onPickupPointFormat(item: ListItem): React.ReactChild {
    const parts = item.name.split('///');
    if(parts.length === 2) {
        return (
            <div className="nshift-option-pickup-point">
                <span className="nshift-option-pickup-point-title">{ parts[0] }</span>
                <br />
                <span className="nshift-option-pickup-point-address">{ parts[1] }</span>
            </div>
        );
    }
    return (
        <div className="nshift-option-pickup-point">
            <span className="nshift-option-pickup-point-title">{ item.name }</span>
        </div>
    );
}
