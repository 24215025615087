const markerSvgString = '<?xml version="1.0" encoding="UTF-8"?><svg width="16px" height="21px" viewBox="0 0 16 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>m@1,5x</title><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="icon/coral/pin" fill="#F67B62" fill-rule="nonzero"><g id="icon/pin"><path d="M8,21 C8.36111132,21 8.63888868,20.8632815 8.83333333,20.5898438 L11.625,16.6523438 C13.0138887,14.6835938 13.9305553,13.3574221 14.375,12.6738281 C15.0138887,11.6894531 15.4444447,10.8623045 15.6666667,10.1923828 C15.8888887,9.52246115 16,8.74999979 16,7.875 C16,6.45312479 15.6388887,5.14062479 14.9166667,3.9375 C14.1944447,2.73437521 13.222222,1.77734354 12,1.06640625 C10.777778,0.355468959 9.44444466,0 8,0 C6.55555534,0 5.22222201,0.355468959 4,1.06640625 C2.77777799,1.77734354 1.80555534,2.73437521 1.08333333,3.9375 C0.361111323,5.14062479 0,6.45312479 0,7.875 C0,8.74999979 0.111111323,9.52246115 0.333333333,10.1923828 C0.555555344,10.8623045 0.986111323,11.6894531 1.625,12.6738281 C2.06944466,13.3574221 2.98611132,14.6835938 4.375,16.6523438 C5.51388868,18.2382815 6.44444466,19.5507815 7.16666667,20.5898438 C7.36111132,20.8632815 7.63888868,21 8,21 Z M8.5,11 C7.5375,11 6.71354189,10.6572919 6.028125,9.971875 C5.34270811,9.28645811 5,8.4625 5,7.5 C5,6.5375 5.34270811,5.71354189 6.028125,5.028125 C6.71354189,4.34270811 7.5375,4 8.5,4 C9.4625,4 10.2864581,4.34270811 10.971875,5.028125 C11.6572919,5.71354189 12,6.5375 12,7.5 C12,8.4625 11.6572919,9.28645811 10.971875,9.971875 C10.2864581,10.6572919 9.4625,11 8.5,11 Z" id="m"></path></g></g></g></svg>';
export const markerSvgURL = encodeURI("data:image/svg+xml," + markerSvgString).replace("#", "%23");

const emailSvgString = '<?xml version="1.0" encoding="UTF-8"?><svg width="23px" height="36px" viewBox="0 0 23 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Mail@1x</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons-and-other-components" transform="translate(-361.000000, -517.000000)"><g id="Mail" transform="translate(362.000000, 518.000000)"><g id="Group" stroke="#F67B62" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><g id="icon/mail"><rect id="Rectangle-path" x="0.4375" y="0.45975" width="20.125" height="14" rx="2"></rect><polyline id="Shape" points="20.047125 0.969 10.5 8.77225 0.952875 0.969"></polyline></g></g></g></g></g></svg>';
export const emailSvgURL = encodeURI("data:image/svg+xml," + emailSvgString).replace("#", "%23");

const phoneSvgString = '<?xml version="1.0" encoding="UTF-8"?><svg width="18px" height="26px" viewBox="0 0 18 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>coral-phone</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="4.3-Identify" transform="translate(-35.000000, -323.000000)" stroke="#F67B62" stroke-width="2"><g id="Group-3" transform="translate(24.000000, 312.000000)"><g id="coral-phone" transform="translate(12.000000, 12.000000)"><rect id="Rectangle-path" x="0.5" y="0.5" width="15" height="23" rx="2"></rect><line x1="15.5" y1="19.5" x2="0.5" y2="19.5" id="Shape"></line><line x1="5.5" y1="2.5" x2="10.5" y2="2.5" id="Shape"></line><path d="M8,21 C8.13807119,21 8.25,21.1119288 8.25,21.25 C8.25,21.3880712 8.13807119,21.5 8,21.5 C7.86192881,21.5 7.75,21.3880712 7.75,21.25 C7.75,21.1119288 7.86192881,21 8,21" id="Shape"></path></g></g></g></g></svg>';
export const phoneSvgURL = encodeURI("data:image/svg+xml," + phoneSvgString).replace("#", "%23");

const doorCodeSvgString = '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>dialpad-code_coral</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="dialpad-code_coral"><polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon><path d="M12,19 C10.9,19 10,19.9 10,21 C10,22.1 10.9,23 12,23 C13.1,23 14,22.1 14,21 C14,19.9 13.1,19 12,19 Z M6,1 C4.9,1 4,1.9 4,3 C4,4.1 4.9,5 6,5 C7.1,5 8,4.1 8,3 C8,1.9 7.1,1 6,1 Z M6,7 C4.9,7 4,7.9 4,9 C4,10.1 4.9,11 6,11 C7.1,11 8,10.1 8,9 C8,7.9 7.1,7 6,7 Z M6,13 C4.9,13 4,13.9 4,15 C4,16.1 4.9,17 6,17 C7.1,17 8,16.1 8,15 C8,13.9 7.1,13 6,13 Z M18,5 C19.1,5 20,4.1 20,3 C20,1.9 19.1,1 18,1 C16.9,1 16,1.9 16,3 C16,4.1 16.9,5 18,5 Z M12,13 C10.9,13 10,13.9 10,15 C10,16.1 10.9,17 12,17 C13.1,17 14,16.1 14,15 C14,13.9 13.1,13 12,13 Z M18,13 C16.9,13 16,13.9 16,15 C16,16.1 16.9,17 18,17 C19.1,17 20,16.1 20,15 C20,13.9 19.1,13 18,13 Z M18,7 C16.9,7 16,7.9 16,9 C16,10.1 16.9,11 18,11 C19.1,11 20,10.1 20,9 C20,7.9 19.1,7 18,7 Z M12,7 C10.9,7 10,7.9 10,9 C10,10.1 10.9,11 12,11 C13.1,11 14,10.1 14,9 C14,7.9 13.1,7 12,7 Z M12,1 C10.9,1 10,1.9 10,3 C10,4.1 10.9,5 12,5 C13.1,5 14,4.1 14,3 C14,1.9 13.1,1 12,1 Z" id="Shape" fill="#F67B62" fill-rule="nonzero"></path></g></g></svg>';
export const doorCodeSvgURL = encodeURI("data:image/svg+xml," + doorCodeSvgString).replace("#", "%23");

const contactSvgString = '<?xml version="1.0" encoding="UTF-8"?><svg width="31px" height="41px" viewBox="0 0 31 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Avatar</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icons-and-other-components" transform="translate(-93.000000, -592.000000)"><g id="Avatar" transform="translate(93.000000, 593.000000)"><g id="Group" transform="translate(4.000000, 0.000000)" stroke="#F67B62" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><g id="Light"><circle id="Oval" cx="11.5" cy="11.5" r="11.0208333"></circle><path d="M3.79404167,19.3784583 C5.43047619,18.4295722 7.17120312,17.6732097 8.9815,17.1244583 C9.783625,16.8283333 9.87083333,14.9883333 9.29583333,14.3558333 C8.46591667,13.4425417 7.7625,12.3720833 7.7625,9.78841667 C7.66471565,8.74722375 8.01783774,7.71404687 8.73244576,6.95051569 C9.44705377,6.18698451 10.45462,5.7663183 11.5,5.79504167 C12.54538,5.7663183 13.5529462,6.18698451 14.2675542,6.95051569 C14.9821623,7.71404687 15.3352843,8.74722375 15.2375,9.78841667 C15.2375,12.3759167 14.5340833,13.4425417 13.7041667,14.3558333 C13.1291667,14.9883333 13.216375,16.8283333 14.0185,17.1244583 C15.8287969,17.6732097 17.5695238,18.4295722 19.2059583,19.3784583" id="Shape"></path></g></g></g></g></g></svg>';
export const contactSvgURL = encodeURI("data:image/svg+xml," + contactSvgString).replace("#", "%23");