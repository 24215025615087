import * as React from 'react';
import { useCallback } from 'react';
import Select, { FormatOptionLabelMeta } from 'react-select';
import { usePopupContainer } from 'v2/utils/popup-container';
import translatedTexts from '../../v1/translated-texts';
import { ListItem } from '../utils/internal-types';

export interface Props {
    id: string;
    name: string;
    label?: string;
    value: string;
    clearable: boolean;
    disabled: boolean;
    items: ListItem[];
    errorMessage?: string;
    onFormatItem?: (item: ListItem) => React.ReactChild;
    onChange: (itemId: string) => void;
}

function getOptionLabel(item: ListItem): string {
    return item.name;
}

function getOptionValue(item: ListItem): string {
    return item.value;
}

export function InputDropDown(props: Props): JSX.Element {
    const {
        id,
        name,
        label,
        value,
        clearable,
        disabled,
        items,
        errorMessage,
        onFormatItem,
        onChange,
    } = props;
    const popupContainerElement = usePopupContainer();
    const valueItem = items.find((item) => item.value === value);
    function onBlockClickCallback(e: React.SyntheticEvent<HTMLElement>): void {
        e.preventDefault();
    }
    const onChangeCallback = useCallback((item: ListItem) => {
        onChange(item.value);
    }, [onChange]);
    const onFormatItemCallback = useCallback((item: ListItem, meta: FormatOptionLabelMeta<ListItem>): React.ReactNode => (meta.context === 'menu' ? (
        <>
            <div className={ `nshift-input-radio ${meta.selectValue.includes(item) ? 'nshift-checked' : ''}` }>
                <span className="nshift-input-radio-overlay1">
                    <span className="nshift-input-radio-overlay2" />
                </span>
            </div>
            <div className="nshift-text">
                { onFormatItem ? onFormatItem(item) : item.name }
            </div>
        </>
    ) : (
        <div className="nshift-text">
            { onFormatItem ? onFormatItem(item) : item.name }
        </div>
    )), [onFormatItem]);
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
            onClick={ disabled ? undefined : onBlockClickCallback }
            // eslint-disable-next-line max-len
            className={ `nshift-input-dropdown ${disabled ? 'nshift-disabled' : 'nshift-enabled'} ${valueItem ? 'nshift-filled' : 'nshift-empty'} ${errorMessage ? 'nshift-error' : ''} ${label ? 'nshift-labelled' : ''}` }
        >
            <div className="nshift-input-dropdown-label-control">
                { label ? (
                    <label htmlFor={ id } className="nshift-input-dropdown-label">
                        <span>{ label }</span>
                    </label>
                ) : null }
                <Select
                    inputId={ id }
                    name={ name }
                    value={ valueItem }
                    options={ items }
                    isClearable={ clearable }
                    isDisabled={ disabled }
                    isSearchable={ false }
                    openMenuOnClick={ true }
                    getOptionLabel={ getOptionLabel }
                    getOptionValue={ getOptionValue }
                    formatOptionLabel={ onFormatItemCallback }
                    className="nshift-input-dropdown-control"
                    classNamePrefix="nshift-input-dropdown-control"
                    placeholder={ label ? '' : translatedTexts.texts.chooseLabel }
                    menuPortalTarget={ popupContainerElement }
                    menuPlacement="auto"
                    onChange={ onChangeCallback }
                />
            </div>
            { errorMessage && !disabled ? (
                <div className="nshift-input-dropdown-message nshift-error">{ errorMessage }</div>
            ) : null }
        </div>
    );
}
