interface LanguageMap {
    [lang: string]: string;
}

export interface TextMap {
    [id: string]: string;
    agentInfoLabel: string;
    agentInfoLabelShort: string;
    fieldsLabel: string;
    fieldsLabel2: string;
    mandatoryField: string;
    minLengthField: string;
    maxLengthField: string;
    minValueField: string;
    maxValueField: string;
    patternField: string;
    emailField: string;
    phoneField: string;
    numberField: string;
    digitsField: string;
    selectOneOrMoreAddon: string;
    openingHours: string;
}

type TextDataMap<T> = {
    [P in keyof T]: LanguageMap;
};

const textsData: TextDataMap<TextMap> = {
    agentInfoLabel: {
        sv: 'Mer information om valt ombud',
        en: 'More information about selected service point',
        da: 'Flere oplysninger om den valgte udleveringssted',
        fi: 'Lisätietoa valitusta toimittajasta',
        no: 'Mer informasjon om valgt utleveringssted',
        de: 'Weitere Informationen zur gewählten Paketstation',
        ru: 'Информация о почтовой точке выдачи',
        es: 'Más información sobre el punto de servicio seleccionado',
        cs: 'Další informace o zvoleném servisním místě',
        pl: 'Więcej informacji o wybranym punkcie serwisowym',
        et: 'Lisateave valitud teenuspunkti kohta',
        fr: 'Plus d\'informaions sur le point de service sélectionné',
        nl: 'Meer informatie over het gekozen servicepunt',
        it: 'Altre info sul punto di servizio selezionato',
        lv: 'Vairāk informācijas izraudzītajā pakalpojuma vietā',
        bg: 'Повече информация за избраната сервизна точка',
        pt: 'Mais informações sobre o ponto de serviço selecionado',
        hu: 'További információk a kiválasztott szolgáltatási pontról',
        sk: 'Viac informácií o zvolenom služobnom mieste',
        el: 'Περισσότερες πληροφορίες για το επιλεγμένο σημείο εξυπηρέτησης',
        tr: 'Seçilen hizmet noktası hakkında daha fazla bilgi',
        ro: 'Mai multe informații despre punctul de service selectat'

    },
    agentInfoLabelShort: {
        sv: 'Mer information',
        en: 'More information',
        da: 'Flere oplysninger',
        fi: 'Lisää tietoa',
        no: 'Mer informasjon',
        de: 'Weitere information',
        ru: 'Дополнительная информация',
        es: 'Más información',
        cs: 'Další informace',
        pl: 'Więcej informacji',
        et: 'Rohkem informatsiooni',
        fr: 'Plus d\'informations',
        nl: 'Meer informatie',
        it: 'Altre info',
        lv: 'Vairāk informācijas',
        bg: 'Повече информация',
        pt: 'Mais informações',
        hu: 'További információk',
        sk: 'Viac informácií',
        el: 'Περισσότερες πληροφορίες',
        tr: 'Daha fazla bilgi',
        ro: 'Mai multe informații'
        
    },
    agentSelectLabel: {
        sv: 'Välj utlämningsställe',
        en: 'Select pickup point',
        da: "Vælg afhentningssted",
        fi: "Valitse noutopiste",
        no: "Velg utleveringssted",
        de: "Abholpunkt auswählen",
        ru: "Выберите пункт самовывоза",
        es: "Seleccionar punto de recogida",
        cs: "Vyberte místo dodání",
        pl: "Wybierz punkt odbioru",
        et: "Valige vastuvõtupunkt",
        fr: "Sélectionnez le point de ramassage",
        nl: "Selecteer afhaalpunt",
        it: "Selezionare il punto di ritiro",
        lv: "Atlasiet saņemšanas punktu",
        bg: "Изберете точка на вземане",
        pt: "Selecione o ponto de coleta",
        hu: 'Válasszon felvételi pontot',
        sk: 'Vyberte odberné miesto',
        el: 'Επιλέξτε σημείο παραλαβής',
        tr: 'Teslim alma noktası seçin',
        ro: 'Selectați punctul de preluare'
    },
    fieldsLabel: {
        sv: 'Mer information',
        en: 'More information',
        da: 'Flere oplysninger',
        fi: 'Lisää tietoa',
        no: 'Mer informasjon',
        de: 'Weitere information',
        ru: 'Дополнительная информация',
        es: 'Más información',
        cs: 'Další informace',
        pl: 'Więcej informacji',
        et: 'Rohkem informatsiooni',
        fr: 'Plus d\'informations',
        nl: 'Meer informatie',
        it: 'Altre info',
        lv: 'Vairāk informācijas',
        bg: 'Повече информация',
        pt: 'Mais informações',
        hu: 'További információk',
        sk: 'Viac informácií',
        el: 'Περισσότερες πληροφορίες',
        tr: 'Daha fazla bilgi',
        ro: 'Mai multe informații'
    },
    fieldsLabel2: {
        sv: 'Övriga värden',
        en: 'Other values',
        da: 'Andre værdier',
        fi: 'Muut arvot',
        no: 'Øvrige verdier',
        de: 'Andere Werte',
        ru: 'Прочие данные',
        es: 'Otros valores',
        cs: 'Jiné hodnot',
        pl: 'Inne wartości',
        et: 'Muud väärtused',
        fr: 'Autres valeurs',
        nl: 'Andere waarden',
        it: 'Altri valori',
        lv: 'Citas vērtība',
        bg: 'Други стойности',
        pt: 'Outros valores',
        hu: 'Egyéb értékek',
        sk: 'Iné hodnoty',
        el: 'Άλλες τιμές',
        tr: 'Diğer değerler',
        ro: 'Alte valori'
    },
    mandatoryField: {
        sv: 'Du måste ange ett värde för detta fält',
        en: 'You must enter a value for this field',
        da: 'Du skal indtaste en værdi for dette felt',
        fi: 'Tähän kenttään on annettava arvo',
        no: 'Det må angis en verdi for dette feltet',
        de: 'Sie müssen einen Wert für dieses Feld angeben',
        ru: 'Это поле обязательно для заполнения',
        es: 'Debe introducir un valor en este campo',
        cs: 'Do tohoto políčka musíte zadat hodnotu',
        pl: 'Musisz wprowadzić wartość dla tego pola',
        et: 'Kohustuslik sisestada kontaktandmed',
        fr: 'Vous devez entrer une valeur pour ce champ',
        nl: 'Dit veld is verplicht',
        it: 'Devi inserire un valore per questo campo',
        lv: 'Šajā laukā ir jāievada vērtība',
        bg: 'Трябва да въведете стойност за това поле',
        pt: 'Tem de introduzir um valor para este campo',
        hu: 'Ebbe a mezőbe kötelező értéket írni',
        sk: 'Do tohto poľa musíte zadať hodnotu',
        el: 'Πρέπει να καταχωρίσετε μια τιμή για το πεδίο αυτό',
        tr: 'Bu alan için bir değer girmelisiniz',
        ro: 'Trebuie să introduceți o valoare pentru acest câmp'
    },
    maxLengthField: {
        sv: 'Värdet är för långt',
        en: 'The value is too long',
        da: 'Værdien er for lang',
        fi: 'Arvo on liian pitkä',
        no: 'Verdien er for lang',
        de: 'Der Wert ist zu lang',
        ru: 'Указанная длина превышает допустимую',
        es: 'El valor es demasiado largo',
        cs: 'Hodnota je příliš dlouhá',
        pl: 'Wartość jest za długa',
        et: 'Liiga palju numbreid',
        fr: 'La valeur est trop longue',
        nl: 'De tekst is te lang',
        it: 'Valore troppo lungo',
        lv: 'Šī vērtība ir pārāk gara',
        bg: 'Стойността е твърде дълга',
        pt: 'O valor é demasiado extenso',
        hu: 'Az érték túl hosszú',
        sk: 'Hodnota je príliš dlhá',
        el: 'Πολύ μεγάλο μήκος τιμής',
        tr: 'Değer çok uzun',
        ro: 'Valoarea este prea lungă'
    },
    minLengthField: {
        sv: 'Värdet är för kort',
        en: 'The value is too short',
        da: 'Værdien er for kort',
        fi: 'Arvo on liian lyhyt',
        no: 'Verdien er for kort',
        de: 'Der Wert ist zu kurz',
        ru: 'Указанная длина меньше допустимой',
        es: 'El valor es demasiado corto',
        cs: 'Hodnota je příliš krátká',
        pl: 'Wartość jest za krótka',
        et: 'Liiga vähe numbreid',
        fr: 'La valeur est trop courte',
        nl: 'De tekst is te kort',
        it: 'Valore troppo corto',
        lv: 'Šī vērtība ir pārāk īsa',
        bg: 'Стойността е твърде къса',
        pt: 'O valor é demasiado curto',
        hu: 'Az érték túl rövid',
        sk: 'Hodnota je príliš krátka',
        el: 'Πολύ μικρό μήκος τιμής',
        tr: 'Değer çok kısa',
        ro: 'Valoarea este prea scurtă'
    },
    maxValueField: {
        sv: 'Värdet är för stort',
        en: 'The value is too big',
        da: 'Værdien er for stor',
        fi: 'Arvo on liian suuri',
        no: 'Verdien er for stor',
        de: 'Der Wert ist zu gross',
        ru: 'Указанный объем превышает допустимый',
        es: 'El valor es demasiado grande',
        cs: 'Hodnota je příliš velká',
        pl: 'Wartość jest za duża',
        et: 'Liiga suur kaal',
        fr: 'La valeur est trop grande',
        nl: 'De waarde is te groot',
        it: 'Valore troppo grande',
        lv: 'Šī vērtība ir pārāk liela',
        bg: 'Стойността е твърде голяма',
        pt: 'O valor é demasiado grande',
        hu: 'Az érték túl nagy',
        sk: 'Hodnota je príliš veľká',
        el: 'Πολύ μεγάλη τιμή',
        tr: 'Değer çok büyük',
        ro: 'Valoarea este prea mare'
    },
    minValueField: {
        sv: 'Värdet är för litet',
        en: 'The value is too small',
        da: 'Værdien er for lille',
        fi: 'Arvo on liian pieni',
        no: 'Verdien er for liten',
        de: 'Der Wert ist zu klein',
        ru: 'Указанный объем меньше допустимого',
        es: 'El valor es demasiado pequeño',
        cs: 'Hodnota je příliš malá',
        pl: 'Wartość jest za mała',
        et: 'Liiga väike kaal',
        fr: 'La valeur est trop petite',
        nl: 'De waarde is te klein',
        it: 'Valore troppo piccolo',
        lv: 'Šī vērtība ir pārāk maza',
        bg: 'Стойността е твърде малка',
        pt: 'O valor é demasiado pequeno',
        hu: 'Az érték túl kicsi',
        sk: 'Hodnota je príliš malá',
        el: 'Πολύ μικρή τιμή',
        tr: 'Değer çok küçük',
        ro: 'Valoarea este prea mică'
    },
    patternField: {
        sv: 'Värdet är ogiltigt',
        en: 'The value is illegal',
        da: 'Værdien er ugyldig',
        fi: 'Arvo on virheellinen',
        no: 'Verdien er ugyldig',
        de: 'Der Wert ist ungültig',
        ru: 'Поле содержит недопустимые знаки',
        es: 'El valor es ilegal',
        cs: 'Hodnota není povolena',
        pl: 'Wartość jest niedozwolona',
        et: 'Keelatud tähemärgid',
        fr: 'La valeur n\'est pas valide',
        nl: 'Deze waarde is ongeldig',
        it: 'Valore non ammesso',
        lv: 'Vērtība ir nederīga',
        bg: 'Стойността е незаконна',
        pt: 'O valor é ilegal',
        hu: 'Az érték érvénytelen',
        sk: 'Hodnota je nezákonná',
        el: 'Η τιμή δεν είναι αποδεκτή',
        tr: 'Değer geçersiz',
        ro: 'Valoarea este ilegală'
    },
    emailField: {
        sv: 'Du måste ange en giltig e-postadress',
        en: 'You must enter a valid email address',
        da: 'Du skal indtaste en gyldig e-mail adresse',
        fi: 'Anna voimassa oleva sähköposti',
        no: 'Du må angi en gyldig e-postadresse',
        de: 'Sie müssen eine gültige E-mail-Adresse angeben',
        ru: 'Укажите действительный электронный адрес',
        es: 'Debe introducir una dirección de correo electrónico válida',
        cs: 'Musíte zadat platnou e-mailovou adresu',
        pl: 'Musisz wprowadzić aktualny adres e-mail',
        et: 'Sisesta kehtiv e-posti aadress',
        fr: 'Vous devez entrer une adresse mail valide',
        nl: 'Voer een geldig e-mailadres in',
        it: 'Devi inserire un indirizzo e-mail valido',
        lv: 'Jāievada derīga e-pasta adrese',
        bg: 'Трябва да въведете валиден имейл адрес',
        pt: 'Tem de introduzir um endereço de e-mail válido',
        hu: 'Érvényes e-mail címet kell megadni',
        sk: 'Musíte zadať platnú e-mailovú adresu',
        el: 'Πρέπει να καταχωρίσετε μια έγκυρη διεύθυνση e-mail',
        tr: 'Geçerli bir e-posta adresi girmelisiniz',
        ro: 'Trebuie să introduceți o adresă de E-mail validă'
    },
    phoneField: {
        sv: 'Du måste ange ett giltigt telefonnummer',
        en: 'You must enter a valid phone number',
        da: 'Du skal indtaste et gyldigt telefonnummer',
        fi: 'Anna voimassa oleva puhelinnumero',
        no: 'Du må angi et gyldig telefonnummer',
        de: 'Sie müssen eine gültige Telefonnummer angeben',
        ru: 'Укажите действительный номер телефона',
        es: 'Debe introducir un número de teléfono válido',
        cs: 'Musíte zadat platné telefonní číslo',
        pl: 'Musisz wprowadzić aktualny numer telefonu',
        et: 'Sisesta kehtiv mobiilinumber',
        fr: 'Vous devez entrer un numéro de téléphone valide',
        nl: 'Voer een geldig telefoonnummer in',
        it: 'Devi inserire un numero di telefono valido',
        lv: 'Jāievada derīgs tālruņa numurs',
        bg: 'Трябва да въведете валиден телефонен номер',
        pt: 'Tem de introduzir um número de telefone válido',
        hu: 'Érvényes telefonszámot kell megadni',
        sk: 'Musíte zadať platné telefónne číslo',
        el: 'Πρέπει να καταχωρίσετε έναν έγκυρο αριθμό τηλεφώνου',
        tr: 'Geçerli bir telefon numarası girmelisiniz',
        ro: 'Trebuie să introduceți un număr de telefon valid'
    },
    numberField: {
        sv: 'Du måste ange ett giltigt nummer',
        en: 'You must enter a valid number',
        da: 'Du skal indtaste et gyldigt nummer',
        fi: 'Anna voimassa oleva numero',
        no: 'Du må angi et gyldig nummer',
        de: 'Sie müssen eine gültige Nummer angeben',
        ru: 'Укажите действительный номер',
        es: 'Debe introducir un número válido',
        cs: 'Musíte zadat platné číslo',
        pl: 'Musisz wprowadzić aktualny numer',
        et: 'Sisesta kehtiv number',
        fr: 'Vous devez entrer un numéro valide',
        nl: 'Voer een geldig getal in',
        it: 'Devi inserire un numero valido',
        lv: 'Jāievada derīgs numurs',
        bg: 'Трябва да въведете валиден номер',
        pt: 'Tem de introduzir um número válido',
        hu: 'Érvényes számot kell megadni',
        sk: 'Musíte zadať platné číslo',
        el: 'Πρέπει να καταχωρίσετε έναν έγκυρο αριθμό',
        tr: 'Geçerli bir numara girmelisiniz',
        ro: 'Trebuie să introduceți un număr valid'
    },
    digitsField: {
        sv: 'Du måste ange en sekvens av siffror',
        en: 'You must enter a sequence of digits',
        da: 'Du skal indtaste en sekvens af tal',
        fi: 'Anna sarja numeroita',
        no: 'Du må angi en sekvens med sifre',
        de: 'Sie müssen eine Ziffernfolge angeben',
        ru: 'Пожалуйста, вводите только цифры',
        es: 'Debe introducir una secuencia de dígitos',
        cs: 'Musíte zadat sekvenci čísel',
        pl: 'Musisz wprowadzić sekwencję cyfr',
        et: 'Sisesta kehtiv numbrijada',
        fr: 'Vous devez entrer une suite de chiffres',
        nl: 'Voer een cijferreeks in',
        it: 'Devi inserire una sequenza di cifre',
        lv: 'Jāievada ciparu secība',
        bg: 'Трябва да въведете поредица от цифри',
        pt: 'Tem de introduzir uma sequência de dígitos',
        hu: 'Számsort kell megadni',
        sk: 'Musíte zadať rad číslic',
        el: 'Πρέπει να καταχωρίσετε μια ακολουθία ψηφίων',
        tr: 'Bir rakam dizisi girmelisiniz',
        ro: 'Trebuie să introduceți o secvență de cifre'
    },
    selectOneOrMoreAddon: {
        sv: 'Välj minst en',
        en: 'Select at least one',
        da: 'Vælg mindst en',
        fi: 'Valitse vähintään yksi',
        no: 'Velg minst én',
        de: 'Wählen Sie mindestens ein',
        ru: 'Выберите как минимум один',
        es: 'Seleccione por lo menos una',
        cs: 'Vyberte alespoň jednu',
        pl: 'Wybierz co najmniej jedną opcję',
        et: 'Valige vähemalt üks',
        fr: 'Vous devez en sélectionner au moins un',
        nl: 'Selecteer er minstens één',
        it: 'Seleziona almeno uno',
        lv: 'Atlasiet vismaz vienu',
        bg: 'Изберете поне един',
        pt: 'Selecione, pelo menos, uma opção',
        hu: 'Válasszon legalább egyet',
        sk: 'Zvoľte aspoň jednu možnosť',
        el: 'Επιλέξτε τουλάχιστον ένα',
        tr: 'En az bir tane seçin',
        ro: 'Selectați cel puțin una'
    },
    openingHours: {
        sv: 'Öppettider',
        en: 'Opening hours',
        da: 'Åbningstider',
        fi: 'Aukioloajat',
        no: 'Åpningstider',
        de: 'Öffnungszeiten',
        ru: 'Часы работы',
        es: 'Horario de apertura',
        cs: 'Otevírací hodiny',
        pl: 'Godziny otwarcia',
        et: 'Lahtiolekuajad',
        fr: 'Horaires d\'ouverture',
        nl: 'Openingstijden',
        it: 'Orario di apertura',
        lv: 'Darba laiks',
        bg: 'Работно време',
        pt: 'Horário de funcionamento',
        hu: 'Nyitvatartás',
        sk: 'Otváracia doba',
        el: 'Ώρες λειτουργίας',
        tr: 'Açılış saatleri',
        ro: 'Ore de deschidere'
    },
    chooseLabel: {
        sv: 'Välj',
        en: 'Select',
        da: 'Vælge',
        fi: 'Valitse',
        no: 'Velg',
        de: 'Wählen',
        ru: 'Выбрать',
        es: 'Seleccione',
        cs: 'Vyberte si',
        pl: 'Wybierz',
        et: 'Vali',
        fr: 'Choisir',
        nl: 'Kies',
        it: 'Seleziona',
        lv: 'Izvēlieties',
        bg: 'Изберете',
        pt: 'Selecione',
        hu: 'Válasszon',
        sk: 'Vyberte',
        el: 'Επιλέξτε',
        tr: 'Seçin',
        ro: 'Alegeți'
    },
    mandatoryAgent: {
        sv: 'Utlämningställe saknas',
        en: 'Agent receiver is missing',
        da: 'Udleveringssted mangler',
        fi: 'Toimitusosoite puuttuu',
        no: 'Leveringssted mangler',
        de: 'Auslieferungsstandort fehlt',
        ru: 'Поле получателя пропущено.',
        es: 'Falta el agente receptor',
        cs: 'Přijímající agent chybí',
        pl: 'Brak przyjmującego agenta',
        et: 'Agent vastuvõtja puudub',
        fr: 'Il manque l\'agent de réception',
        nl: 'Selecteer een ophaallocatie',
        it: 'Destinatario agente mancante',
        lv: 'Nav aģenta saņēmēja',
        bg: 'Липсва приемник агент',
        pt: 'O recetor do agente está em falta',
        hu: 'A meghatalmazott átvevő hiányzik',
        sk: 'Chýba zástupca príjemcu',
        el: 'Παραλήπτης πράκτορας λείπει',
        tr: 'Bayi alıcısı eksik',
        ro: 'Receptorul agentului lipsește'
    },
    closed: {
        sv: 'Stängt',
        en: 'Closed',
        da: 'Lukket',
        fi: 'Suljettu',
        no: 'Stengt',
        de: 'Geschlossen',
        ru: 'Закрыто',
        es: 'Cerrado',
        cs: 'Uzavřené',
        pl: 'Zamknięte',
        et: 'Suletud',
        fr: 'Fermé',
        nl: 'Gesloten',
        it: 'Chiuso',
        lv: 'Slēgts',
        bg: 'Затворено',
        pt: 'Fechado',
        hu: 'Zárva',
        sk: 'Zavreté',
        el: 'Κλειστό',
        tr: 'Kapalı',
        ro: 'Închis'
    }
};

function translate(language: string): TextMap {
    const result: TextMap = <any>{};
    for(const id in textsData) {
        if(!textsData.hasOwnProperty(id)) {
            continue;
        }
        result[id] = textsData[id][language];
    }
    return result;
}

const translatedTexts = {
    texts: translate('en')
};

const VALID_LANGUAGES = ['sv', 'en', 'de', 'no', 'da', 'fi', 'ru', 'es', 'cs', 'pl', 'et', 'fr', 'nl', 'it', 'lv', 'bg', 'pt', 'hu', 'sk', 'el', 'tr', 'ro'];

export function setLanguage(language: string) {
    language = language.toLowerCase();
    if(VALID_LANGUAGES.indexOf(language) >= 0) {
        translatedTexts.texts = translate(language);
    } else {
        translatedTexts.texts = translate('en');
    }
}

export default translatedTexts;
