import { Addon, Field, Option, AddonValue, ValidationAddonResult, FieldValue } from '../../v1/model';
import * as React from 'react';
import { useCallback } from 'react';
import { buildAddonState } from '../utils/internal-state';
import { FieldItem } from './field-item';
import { InputCheck } from './input-check';
import { InputRadio } from './input-radio';

export interface Props {
    sessionId: string;
    option: Option | undefined;
    addon: Addon;
    addonState: AddonValue | undefined;
    disabled: boolean;
    validationResult?: ValidationAddonResult;
    onChange: (addon: Addon, addonState: AddonValue) => void;
}

export function AddonItem(props: Props): JSX.Element {
    const {
        sessionId,
        option,
        addon,
        addonState,
        disabled,
        validationResult,
        onChange,
    } = props;
    const onChangeCheckCallback = useCallback((value: boolean) => {
        onChange(addon, buildAddonState(addon, addonState, { selected: value }));
    }, [option, addon, addonState]);
    const onChangeRadioCallback = useCallback(() => {
        onChange(addon, buildAddonState(addon, addonState, { selected: true }));
    }, [option, addon, addonState]);
    const onChangeFieldCallback = useCallback((field: Field, fieldState: FieldValue) => {
        onChange(addon, buildAddonState(addon, addonState, {
            fields: {
                ...(addonState?.fields ?? {}),
                [field.id]: fieldState
            }
        }));
    }, [option, addon, addonState]);
    const id = `CheckoutAddon${sessionId}${option?.id ?? ''}${addon.id}`;
    return (
        <li className="nshift-addon">
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
            <label
                className={ `nshift-addon-label ${(validationResult && validationResult.message) ? 'nshift-error' : ''}` }
                htmlFor={ id }
            >
                { addon.oneOf && (addon.oneOf.length > 0) ? (
                    <InputRadio
                        id={ id }
                        name={ id }
                        value={ addon.id }
                        checked={ !!addonState?.selected }
                        disabled={ !!addon.mandatory || disabled }
                        onChange={ onChangeRadioCallback }
                    />
                ) : (
                    <InputCheck
                        id={ id }
                        name={ id }
                        value={ addon.id }
                        checked={ !!addonState?.selected }
                        disabled={ !!addon.mandatory || disabled }
                        onChange={ onChangeCheckCallback }
                    />
                ) }
                <span className="nshift-addon-title">
                    { addon.description }
                    { validationResult && validationResult.message && !disabled ? (
                        <span className="nshift-addon-message nshift-error">{ validationResult.message }</span>
                    ) : null }
                </span>
                <span className="nshift-addon-price1">{ addon.priceDescription }</span>
            </label>
            { addon.fields && (addon.fields.length > 0) ? (
                <div className="nshift-addon-fields">
                    { (addon.fields ?? []).map((field) => (
                        <FieldItem
                            key={ field.id }
                            idPrefix={ id }
                            field={ field }
                            fieldState={ (addonState?.fields ?? {})[field.id] }
                            disabled={ disabled }
                            validationResult={ (validationResult?.fields ?? {})[field.id] }
                            onChange={ onChangeFieldCallback }
                        />
                    ))}
                </div>
            ) : null }
        </li>
    );
}
