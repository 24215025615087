import * as React from 'react';
import TranslatedTexts from '../translated-texts';
import { Icon, Point } from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Option, Agent, Coordinate } from '../model';
import { markerSvgURL } from './svgs';

export interface MapProps {
    option: Option;
    activeAgent?: Agent;
    center?: Coordinate;
    setAgent: (agent?: Agent) => void;
    setCenter: (agent?: Agent) => void;
    onMapAgentChange: (agentId: string) => void;
    onToggleInfo: (event: React.SyntheticEvent<any, Event>) => void;
}

const tileLayerData = {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    maxZoom: 19,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
};

const marker = new Icon({iconUrl: markerSvgURL, iconSize: [32, 32]});

export default class LeafletMap extends React.Component<MapProps> {

    constructor(props: MapProps) {
        super(props);
    }

    public render() {
        if (this.props.option.agents && this.props.option.agents.filter((agent: Agent) => agent.mapLatitude && agent.mapLongitude).length > 0) {
            return (
                <Map center={this.props.center} zoom={16} maxZoom={17} minZoom={5} zoomControl={false}>
                    <TileLayer url={tileLayerData.url} attribution={tileLayerData.attribution} maxZoom={tileLayerData.maxZoom}/>
                    {this.renderAgents()}
                    {this.renderPopUp()}
                </Map>
            );
        } else {
            return null;
        }
    }

    public componentDidMount() {
        if(this.props.activeAgent) {
            this.props.setAgent(this.props.activeAgent);
            this.props.setCenter(this.props.activeAgent);
        } else if (this.props && this.props.option && this.props.option.agents && this.props.option.agents.length > 0) {
            this.props.setAgent(this.props.option.agents[0]);
            this.props.setCenter(this.props.option.agents[0]);
        }
    }

    private renderPopUp() {
        return this.props.activeAgent && (
            <Popup
                position={[this.props.activeAgent.mapLatitude, this.props.activeAgent.mapLongitude]}
                onClose={() => this.props.setAgent(undefined)}
                closeButton={false}
                offset={new Point(1, -10)}>
                {this.agentPopupInfo(this.props.activeAgent)}
            </Popup>
        );
    }

    private agentPopupInfo(agent: Agent) {
        const name = agent.name != null ? agent.name : '';
        const address = agent.address1 != null ? agent.address1 : '';
        const zipCode = agent.zipCode != null ? agent.zipCode : '';
        const city = agent.city != null ? agent.city : '';
        return (
            <div className="unifaun-map-popup">
                {name} <br/>
                {address} <br/>
                {zipCode + ' '}
                {city} <br/>
                <a href="" onClick={ this.props.onToggleInfo } className="unifaun-checkout-map-info">
                    { TranslatedTexts.texts.agentInfoLabelShort}
                </a>
            </div>
        );
    }

    private renderAgents() {
        if (!this.props.option || !this.props.option.agents) {
            return null;
        } else {
            return this.props.option.agents
                .filter((agent: Agent) => agent.mapLatitude && agent.mapLongitude)
                .map((agent: Agent) => {
                    return this.renderMarker(agent);
                });
        }
    }

    private renderMarker(agent: Agent) {
        return (
            <Marker
                position={[agent.mapLatitude, agent.mapLongitude]}
                key={agent.id}
                onclick={() => {
                        this.props.onMapAgentChange(agent.id);
                    }
                }
                icon={marker}
            />
        );
    }
}
