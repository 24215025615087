import { createContext, useContext } from 'react';

export type ResizeCallback = (size: { w: number, h: number }) => void;

export class ResizeManager {
    private observer: ResizeObserver;
    private listeners: WeakMap<Element, ResizeCallback> = new WeakMap();

    constructor() {
        this.observer = new ResizeObserver(this.onResize);
    }

    public add(elem: Element, onResizeCallback: ResizeCallback): () => void {
        this.observer.observe(elem, { box: 'border-box' });
        this.listeners.set(elem, onResizeCallback);
        return (): void => {
            this.observer.unobserve(elem);
            this.listeners.delete(elem);
        };
    }

    private onResize = (entries: ResizeObserverEntry[]): void => {
        entries.forEach((entry) => {
            const size = { w: 0, h: 0 };
            if(entry.borderBoxSize && entry.borderBoxSize[0]) {
                size.w = entry.borderBoxSize[0].inlineSize;
                size.h = entry.borderBoxSize[0].blockSize;
            } else if(entry.target) {
                const rect = entry.target.getBoundingClientRect();
                size.w = rect.width;
                size.h = rect.height;
            }
            const callback = this.listeners.get(entry.target);
            if(callback) {
                callback(size);
            }
        });
    }
}

export const ResizeManagerContext = createContext<ResizeManager>(new ResizeManager());

export function useResizeManager(): ResizeManager {
    return useContext(ResizeManagerContext);
}

const ResizeManagerProvider = ResizeManagerContext.Provider;
export default ResizeManagerProvider;
