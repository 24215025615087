import * as React from 'react';

export interface Props {
    id: string;
    name: string;
    label: string;
    value: string;
    disabled: boolean;
    errorMessage?: string;
    onChange: (value: string) => void;
}

export function InputText(props: Props): JSX.Element {
    const {
        id,
        name,
        label,
        value,
        disabled,
        errorMessage,
        onChange,
    } = props;
    function onChangeCallback(e: React.SyntheticEvent<HTMLInputElement>): void {
        onChange(e.currentTarget.value);
    }
    return (
        <span
            // eslint-disable-next-line max-len
            className={ `nshift-input-text ${disabled ? 'nshift-disabled' : 'nshift-enabled'} ${value && value.length > 0 ? 'nshift-filled' : 'nshift-empty'} ${errorMessage ? 'nshift-error' : ''}` }
        >
            <span className="nshift-input-text-label-control">
                { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                <label htmlFor={ id } className="nshift-input-text-label">
                    <span>{ label }</span>
                </label>
                <span className="nshift-input-text-wrapper">
                    <input
                        id={ id }
                        name={ name }
                        value={ value }
                        className="nshift-input-text-control"
                        disabled={ disabled }
                        type="text"
                        onChange={ onChangeCallback }
                    />
                </span>
            </span>
            { errorMessage && !disabled ? (
                <span className="nshift-input-text-message nshift-error">{ errorMessage }</span>
            ) : null }
        </span>
    );
}
