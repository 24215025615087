import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Model from './model';
import { debounce } from './utils';
import { validateAddons, validateFields, validateAgents } from './validation';
import { setLanguage } from './translated-texts';
import RootComponent, { RESIZE_DELAY_MS } from './components/root';
import { WidgetRoot } from '../v2/components/widget-root';
import { AgentWithOptionId } from '../v2/utils/internal-types';

declare function require(path: string): any;

const DEFAULT_VERSION = 1;
const NSHIFT_VERSION = 2;

const MAX_SUB_LEVELS = 10;
const themeMap: { [key: string]: string; } = {
    'nshift': 'unifaun',
    'nshift-mp': 'unifaun-mp'
};

export class UnifaunCheckoutWidget {

    private rootContainerElement: Element | null;
    private rootComponent: RootComponent | null;
    private model: Model.Root;
    private selectedOptionIds: Model.IdMap;
    private selectedSubOptionIds: Model.IdMap;
    private selectedAgentIds: Model.IdMap;
    private animLevel: number;
    private installedCSS: any;
    private installedLeafletCSS: any;
    private addonValues: Model.AddonValueMap;
    private fieldValues: Model.FieldValueMap;
    private configuration: Model.Configuration;
    private disabled: boolean;
    private addonDisabled: Model.AddonDisableMap;
    private validationResult: Model.ValidationResult;
    private iconsBaseUrl: string;
    private enableMap: boolean;
    private popupContainerElement: HTMLElement | undefined;
    private theme: string;
    private version: number;
    private debouncedUpdateResult = debounce(this.updateResult.bind(this), 200);

    constructor(rootContainerElement: Element | null, configuration?: Model.Configuration) {
        this.rootContainerElement = rootContainerElement;
        this.selectedOptionIds = {};
        this.selectedSubOptionIds = {};
        this.selectedAgentIds = {};
        this.animLevel = 0;
        this.addonValues = {};
        this.fieldValues = {};
        this.disabled = false;
        this.addonDisabled = {};
        this.validationResult = { addons: {}, fields: {}, agents: {}, count: 0, customFields: {} };
        this.enableMap = false;
        this.version = DEFAULT_VERSION;

        if(configuration) {
            this.configuration = { ...configuration };
        } else {
            this.configuration = {};
        }
        if(this.configuration.version && (this.configuration.version >= 1) && (this.configuration.version <= 2)) {
            this.version = this.configuration.version;
        }
        if(this.configuration.installCSS) {
            const theme = this.configuration.installCSS === true ? 'unifaun-checkout' : this.configuration.installCSS;
            this.installCSS(theme);
        }
        if(this.configuration.enableMap) {
            this.enableMap = this.configuration.enableMap;
        }
        this.ensurePopupContainerElement();
        if(this.configuration.iconsBaseUrl) {
            this.iconsBaseUrl = this.configuration.iconsBaseUrl.trim();
            if(this.iconsBaseUrl.charAt(this.iconsBaseUrl.length - 1) !== '/') {
                this.iconsBaseUrl = this.iconsBaseUrl + '/';
            }
        } else {
            this.iconsBaseUrl = '';
        }
        this.setLanguage(this.configuration.language);
    }

    private ensurePopupContainerElement() {
        if(!this.popupContainerElement && (this.version === NSHIFT_VERSION)) {
            if(this.configuration.popupContainerElement) {
                const wrapper = document.createElement('div');
                wrapper.setAttribute('class', 'nshift-checkout-widget nshift-theme1 nshift-popup-container');
                this.configuration.popupContainerElement.appendChild(wrapper);
                this.popupContainerElement = wrapper;
            } else {
                this.popupContainerElement = document.createElement('div');
                this.popupContainerElement.setAttribute('class', 'nshift-checkout-widget nshift-theme1 nshift-popup-container');
                document.body.appendChild(this.popupContainerElement);
            }
        }
    }

    public setLanguage(language?: string): void {
        if(!language) {
            language = 'en';
        }
        language = language.toLowerCase();
        switch(language) {
            case 'se':
                language = 'sv';
                break;
            case 'dk':
                language = 'da';
                break;
        }
        setLanguage(language);
    }

    public installCSS(theme: string = 'unifaun-checkout'): void {
        this.theme = themeMap[theme] ?? theme;
        if(this.theme === 'nshift-v2') {
            this.version = NSHIFT_VERSION;
            this.ensurePopupContainerElement();
        }
        if(!this.installedCSS) {
            if(this.version === NSHIFT_VERSION) {
                this.installedCSS = require(`../../../../resources/v2/nshift.css`).default;
            } else {
                this.installedCSS = require(`../../../../resources/v1/${this.theme}.scss`).default;
            }
            this.installedCSS.use();
            if ((this.theme === 'unifaun-mp' || this.theme === 'unifaun' || this.version === NSHIFT_VERSION) && this.configuration.enableMap) {
                this.installedLeafletCSS = require('leaflet/dist/leaflet.css').default;
                this.installedLeafletCSS.use();
            }
        }
    }

    public uninstallCSS(): void {
        this.theme = '';
        if(this.installedCSS) {
            this.installedCSS.unuse();
            this.installedCSS = undefined;
        }
        if(this.installedLeafletCSS) {
            this.installedLeafletCSS.unuse();
            this.installedLeafletCSS = undefined;
        }
    }

    public changeNarrowDisplay(narrow: boolean): void {
        this.configuration.narrowDisplay = narrow;
        this.render();
    }

    public changeNarrowBreakpointWidth(narrowWidth: number, ultraNarrowWidth?: number): void {
        this.configuration.narrowBreakpointWidth = narrowWidth;
        this.configuration.ultraNarrowBreakpointWidth = ultraNarrowWidth;
        this.render();
    }

    public checkWidthBreakpoints() {
        if(this.rootComponent) {
            this.rootComponent.refreshResizeTracking();
        }
    }

    public disable(): void {
        this.disabled = true;
        this.render();
    }

    public enable(): void {
        this.disabled = false;
        this.render();
    }

    public updateList(data: Model.Root, reset: boolean = false): void {
        const doubleRefresh = !this.model || !this.model.options || (this.model.options.length === 0);
        this.model = this.filterList(data);
        const currentOption = this.selectedOption(0);
        let newOption: Model.Option | undefined = undefined;
        if(this.model && this.model.options && (this.model.options.length > 0)) {
            if(currentOption) {
                for(const option of this.model.options) {
                    if(option.id === currentOption.id) {
                        newOption = option;
                        break;
                    }
                }
            }
            if(!newOption) {
                newOption = this.model.options[0];
            }
            this.updateSelectedOption(0, '$', newOption);
        }
        const selectedAgentIds = {};
        this.updateAgentIds(selectedAgentIds, this.model.options, reset);
        this.selectedAgentIds = selectedAgentIds;
        if (reset) {
            this.addonValues = {};
            this.updateAddonValues();
        }
        this.updateAddonDisabled();
        this.validate();
        this.render();
        this.checkWidthBreakpoints();
        if(doubleRefresh) {
            setTimeout(() => {
                this.checkWidthBreakpoints();
            }, RESIZE_DELAY_MS + 5);
        }
        this.updateResult();
    }

    private filterList(data: Model.Root): Model.Root {
        if(!data.options) {
            return data;
        }
        data.options = data.options.filter((option) => {
            return !this.hasUnknownFields(option.fields) && !this.hasUnknownAddons(option.addons);
        });
        data.options.forEach((option) => {
            option.addons = this.filterAddons(option.addons);
            option.addons = this.filterOneOf(option.addons);
            option.fields = this.filterFields(option.fields);
        });
        return data;
    }

    private filterAddons(addons: Model.Addon[] | undefined): Model.Addon[] | undefined {
        if(!addons) {
            return addons;
        }
        addons = addons.filter((addon) => {
            return !this.hasUnknownFields(addon.fields);
        });
        addons.forEach((addon) => {
            addon.fields = this.filterFields(addon.fields);
        });
        return addons;
    }

    private filterOneOf(addons: Model.Addon[] | undefined): Model.Addon[] | undefined {
        if(!addons) {
            return addons;
        }
        addons.forEach((addon) => {
            if(addon.oneOf && addon.oneOf.length > 0) {
                addon.oneOf = addon.oneOf.filter((id) => {
                    return this.hasAddon(addons, id);
                });
            }
        });
        return addons;
    }

    private filterFields(fields: Model.Field[] | undefined): Model.Field[] | undefined {
        if(!fields) {
            return fields;
        }
        return fields.filter((field) => {
            return Model.FIELD_TYPES.indexOf(field.type) >= 0;
        });
    }

    private hasAddon(addons: Model.Addon[], id: string): boolean {
        for(const addon of addons) {
            if(addon.id === id) {
                return true;
            }
        }
        return false;
    }

    private hasUnknownFields(fields: Model.Field[] | undefined): boolean {
        if(!fields) {
            return false;
        }
        for(const field of fields) {
            if((Model.FIELD_TYPES.indexOf(field.type) < 0) && field.mandatory) {
                return true;
            }
        }
        return false;
    }

    private hasUnknownAddons(addons: Model.Addon[] | undefined): boolean {
        if(!addons) {
            return false;
        }
        for(const addon of addons) {
            if(this.hasUnknownFields(addon.fields)) {
                if(addon.oneOf && (addon.oneOf.length > 0)) {
                    for(const other of addons) {
                        if((addon.oneOf.indexOf(other.id) >= 0) && !this.hasUnknownFields(other.fields)) {
                            return false;
                        }
                    }
                }
                return true;
            }
        }
        return false;
    }

    private updateAgentIds(
        selectedAgentIds: Model.IdMap,
        options: Model.Option[],
        reset: boolean = false
    ): void {
        for(const option of options) {
            if(option.agents && (option.agents.length > 0)) {
                let agent: Model.Agent | undefined = undefined;
                let agentId = this.selectedAgentIds[option.id];

                if(agentId && !reset) {
                    agent = this.findAgent(option.agents, agentId);
                }

                if(!agent) {
                    agentId = option.agents[0].id;
                }

                if(agentId && !option.noDefaultAgent) {
                    selectedAgentIds[option.id] = agentId;
                }
            } else {
                delete selectedAgentIds[option.id];
            }
            if(option.subOptions) {
                this.updateAgentIds(selectedAgentIds, option.subOptions, reset);
            }
        }
    }

    public render() {
        if(this.version === NSHIFT_VERSION) {
            ReactDOM.render(
                <WidgetRoot
                    addonValues={ this.addonValues }
                    breakpoints={ [
                        ['nshift-ultra-narrow', this.configuration.ultraNarrowBreakpointWidth ?? 0],
                        ['nshift-narrow', this.configuration.narrowBreakpointWidth ?? 0]
                    ] }
                    disabled={ this.disabled }
                    fieldValues={ this.fieldValues }
                    mapEnabled={ this.enableMap }
                    popupContainerElement={ this.popupContainerElement }
                    iconsBaseUrl={ this.iconsBaseUrl }
                    onChangeAddon={ this.onChangeAddon }
                    onChangeField={ this.onChangeField }
                    onChangeOption={ this.onOptionSelect }
                    onChangePickupPoint={ this.onChangeAgent }
                    options={ this.model }
                    selectedAgentIds={ this.selectedAgentIds }
                    selectedOptionIds={ this.selectedOptionIds }
                    theme="nshift-theme1"
                    validationResult={ this.validationResult }
                />,
                this.rootContainerElement
            );
        } else {
            ReactDOM.render(
                <RootComponent
                    ref={ (comp) => { this.rootComponent = comp; } }
                    model={ this.model }
                    selectedOptionIds={ this.selectedOptionIds }
                    selectedAgentIds={ this.selectedAgentIds }
                    animLevel={ this.animLevel }
                    narrow={ this.configuration.narrowDisplay || false }
                    narrowBreakpointWidth={ this.configuration.narrowBreakpointWidth || 0 }
                    ultraNarrowBreakpointWidth={ this.configuration.ultraNarrowBreakpointWidth }
                    addonValues={ this.addonValues }
                    hideAgentInfo={ this.configuration.hideAgentInfo || false }
                    fieldValues={ this.fieldValues }
                    useIcons={ this.configuration.useIcons || false }
                    iconsInFront={ this.configuration.iconsInFront || false }
                    iconsBaseUrl={ this.iconsBaseUrl }
                    disabled={ this.disabled }
                    addonDisabled={ this.addonDisabled }
                    validationResult={ this.validationResult }
                    enableMap={ this.enableMap }
                    cssStyle={ this.theme }
                    onOptionSelect={ this.onOptionSelect }
                    onAgentSelect={ this.onAgentSelect }
                    onAnimLevel={ this.onAnimLevel }
                    onAddonValueChange={ this.onAddonValueChange }
                    onFieldValueChange={ this.onFieldValueChange }/>,
                this.rootContainerElement
            );
        }
    }

    public getResult(): Model.Result {
        const options: string[] = [];
        let price: number = 0;
        let taxRate: number = 0;
        let agentId: string | undefined = undefined;
        let currentOption: Model.Option | undefined = undefined;
        for(let level = 0; level < MAX_SUB_LEVELS; level++) {
            const option = this.selectedOption(level);
            if(!option) {
                break;
            }
            currentOption = option;
            options.push(option.id);
            price = 1 * option.priceValue;
            taxRate = option.taxRate !== undefined ? 1 * option.taxRate : taxRate;
            agentId = this.selectedAgentIds[option.id];
        }
        const addons = this.createAddonResults(currentOption, this.addonValues);
        const fields = this.createFieldResults(this.fieldValues);
        for(const addon of addons) {
            price = price + addon.price;
        }
        const result: Model.Result = {
            valid: !this.validationResult || (this.validationResult.count <= 0),
            deliveryCheckoutId: this.model.deliveryCheckoutId,
            selectedOptionId: options.length > 0 ? options[options.length - 1] : '',
            optionIds: options,
            price: price,
            taxRate: taxRate,
            agent: agentId,
            selectedAddons: addons,
            fields: fields
        };
        return result;
    }

    private updateResult(): void {
        const result = this.getResult();
        if(this.configuration.resultCallback) {
            this.configuration.resultCallback(result);
        }
    }

    private createFieldResults(fieldValues: Model.FieldValueMap): Model.FieldResult[] {
        const fields: Model.FieldResult[] = [];
        for(const fieldId in fieldValues) {
            if(!fieldValues.hasOwnProperty(fieldId)) {
                continue;
            }
            const field = fieldValues[fieldId];
            fields.push({
                id: field.id,
                value: field.value
            });
        }
        return fields;
    }

    private createAddonResults(option: Model.Option | undefined, addonValues: Model.AddonValueMap): Model.AddonResult[] {
        const addons: Model.Addon[] = (option && option.addons || []).concat(this.model.addons || []);
        const result: Model.AddonResult[] = [];
        for(const addon of addons) {
            const addonValue = addonValues[addon.id];
            if(addonValue && addonValue.selected) {
                result.push({
                    id: addon.id,
                    price: 1 * (addon.price || 0),
                    fields: this.createFieldResults(addonValue.fields)
                });
            }
        }
        return result;
    }

    public setResult(result: Model.Result): void {
        const selectedOptionIds: Model.IdMap = {};
        const selectedSubOptionIds: Model.IdMap = {};
        const option = this.selectOption(selectedOptionIds, selectedSubOptionIds, this.model && this.model.options, result.optionIds, 0, '$');
        this.selectedOptionIds = selectedOptionIds;
        this.selectedSubOptionIds = selectedSubOptionIds;
        this.selectedAgentIds = {};
        this.updateAgentIds(this.selectedAgentIds, this.model.options);
        if(result.selectedOptionId && result.agent) {
            this.selectedAgentIds[result.selectedOptionId] = result.agent;
        }
        this.updateAddonValues();
        const addons: Model.Addon[] = (option && option.addons || []).concat(this.model.addons || []);
        if(addons && addons.length > 0) {
            for(const addon of addons) {
                const selectedAddon = this.findAddonResult(result.selectedAddons, addon.id);
                if(selectedAddon) {
                    let fieldValues = {};
                    const addonValue = this.addonValues[addon.id];
                    for(const selectedField of selectedAddon.fields) {
                        const field = this.findField(addon.fields, selectedField.id);
                        if(field) {
                            if(addonValue && addonValue.fields) {
                                const oldFieldValue = addonValue.fields[field.id];
                                const fieldValue = Model.buildFieldValue(field, oldFieldValue, { value: selectedField.value });
                                fieldValues = {
                                    ...addonValue.fields,
                                    [fieldValue.id]: fieldValue
                                };
                            } else {
                                const fieldValue = Model.buildFieldValue(field, undefined, { value: selectedField.value });
                                fieldValues = {
                                    [fieldValue.id]: fieldValue
                                };
                            }
                        }
                    }

                    this.addonValueChange(addon, Model.buildAddonValue(addon, addonValue, { selected: true, fields: fieldValues }));
                } else {
                    this.addonValueChange(addon, Model.buildAddonValue(addon, this.addonValues[addon.id], { selected: false }));
                }
            }
        }
        this.updateFieldValues();
        if(option && option.fields && result.fields) {
            for(const selectedField of result.fields) {
                const field = this.findField(option.fields, selectedField.id);
                if(field) {
                    const fieldValue = this.fieldValues[field.id];
                    this.fieldValueChange(Model.buildFieldValue(field, fieldValue, { value: selectedField.value }));
                }
            }
        }
        this.updateAddonDisabled();
        this.validate();
        this.render();
        this.updateResult();
    }

    private selectOption(
        selectedOptionIds: Model.IdMap,
        selectedSubOptionIds: Model.IdMap,
        options: Model.Option[] | undefined,
        optionIds: string[],
        level: number,
        parentId: string
    ): Model.Option | undefined {
        if(!options) {
            return undefined;
        }
        for(const option of options) {
            if(this.trimOptionId(option.id) === this.trimOptionId(optionIds[level])) {
                selectedOptionIds[Model.levelKey(level)] = option.id;
                selectedSubOptionIds[parentId] = option.id;
                return this.selectOption(selectedOptionIds, selectedSubOptionIds, option.subOptions, optionIds, level + 1, option.id) || option;
            }
        }
        return undefined;
    }

    private onOptionSelect = (level: number, parentId: string, option: Model.Option): void => {
        this.updateSelectedOption(level, parentId, option);
        this.validate();
        this.render();
        this.updateResult();
    }

    private updateSelectedOption(level: number, parentId: string, option: Model.Option): void {
        const selectedOptionIds = {
            ...this.selectedOptionIds,
            [Model.levelKey(level)]: option.id
        };
        if(parentId && (level > 0) && (selectedOptionIds[Model.levelKey(level - 1)] !== parentId)) {
            selectedOptionIds[Model.levelKey(level - 1)] = parentId;
        }
        const selectedSubOptionIds = {
            ...this.selectedSubOptionIds,
            [parentId]: option.id
        };
        let parentOption: Model.Option | undefined = option;
        for(let subLevel = level + 1; subLevel < MAX_SUB_LEVELS; subLevel++) {
            const levelKey = Model.levelKey(subLevel);
            if(parentOption) {
                let subId = '';
                if(this.selectedSubOptionIds) {
                    subId = this.selectedSubOptionIds[parentOption.id];
                }
                if(parentOption.subOptions && (parentOption.subOptions.length > 0)) {
                    let subOption = undefined;
                    if(subId) {
                        subOption = this.findSubOption(parentOption.subOptions, subId);
                    }
                    if(subOption) {
                        selectedOptionIds[levelKey] = subOption.id;
                        parentOption = subOption;
                    } else {
                        subId = parentOption.subOptions[0].id;
                        selectedOptionIds[levelKey] = subId;
                        selectedSubOptionIds[parentOption.id] = subId;
                        parentOption = undefined;
                    }
                } else {
                    delete selectedOptionIds[levelKey];
                }
            } else {
                delete selectedOptionIds[levelKey];
            }
        }
        this.animLevel = level;
        this.selectedOptionIds = selectedOptionIds;
        this.selectedSubOptionIds = selectedSubOptionIds;
        this.updateAddonValues();
        this.updateFieldValues();
        this.updateAddonDisabled();
    }

    private findSubOption(options: Model.Option[], id: string): Model.Option | undefined {
        for(const option of options) {
            if(this.trimOptionId(option.id) === this.trimOptionId(id)) {
                return option;
            }
        }
        return undefined;
    }

    private trimOptionId(id: string): string {
        const idx = id.indexOf('+');
        if(idx >= 0) {
            return id.substring(0, idx);
        }
        return id;
    }

    private selectedOption(level: number): Model.Option | undefined {
        let options: Model.Option[] | undefined = this.model.options;
        let option: Model.Option | undefined = undefined;
        for(let subLevel = 0; subLevel <= level; subLevel++) {
            const optionId = this.selectedOptionIds[Model.levelKey(subLevel)];
            if(!optionId) {
                return undefined;
            }
            if(!options) {
                return undefined;
            }
            option = this.findSubOption(options, optionId);
            if(!option) {
                return undefined;
            }
            options = option.subOptions;
        }
        return option;
    }

    private agentSelect(optionId: string, agent: Model.Agent): void {
        const selectedAgentIds = {
            ...this.selectedAgentIds,
            [optionId]: agent.id
        };
        this.selectedAgentIds = selectedAgentIds;
    }

    private onAgentSelect = (option: Model.Option, agent: Model.Agent): void => {
        this.agentSelect(option.id, agent);
        this.updateAddonDisabled();
        this.validate();
        this.render();
        this.updateResult();
    }

    private onChangeAgent = (agentWithOptionId: AgentWithOptionId): void => {
        this.agentSelect(agentWithOptionId.optionId, agentWithOptionId);
        this.updateAddonDisabled();
        this.validate();
        this.render();
        this.updateResult();
    }

    private findAgent(agents: Model.Agent[], id: string): Model.Agent | undefined {
        for(const agent of agents) {
            if(agent.id === id) {
                return agent;
            }
        }
        return undefined;
    }

    private onAnimLevel = (level: number): void => {
        this.animLevel = level;
        this.render();
    }

    private addonValueChange(addon: Model.Addon, value: Model.AddonValue): void {
        this.addonValues[value.id] = value;

        if(addon.oneOf && addon.oneOf.length > 0) {
            for(const addonId of addon.oneOf) {
                if(addonId === addon.id) {
                    continue;
                }
                const tmpValue = this.addonValues[addonId];
                if(tmpValue && tmpValue.selected) {
                    this.addonValues[addonId] = {
                        ...tmpValue,
                        selected: false
                    };
                }
            }
        }

        // If current addon is not selected:
        if(!addon.selected) {
            // Loop all addons thats currently selected
            for(const id of Object.keys(this.addonValues)) {
                const aSelectedAddonValue = this.addonValues[id];
                // Loop its possible dependencies
                if(aSelectedAddonValue.dependency) {
                    for(const dependantId of aSelectedAddonValue.dependency) {
                        // If the dependencies contain the current addon (the addon being unselected) also unselect the dependant addon.
                        if(addon.id === dependantId) {
                            aSelectedAddonValue.selected = false;
                        }
                    }
                }
            }
        }
        this.updateAddonDisabled();
        this.validate();
    }

    private onAddonValueChange = (addon: Model.Addon, value: Model.AddonValue): void => {
        this.addonValueChange(addon, value);
        this.render();
        this.updateResult();
    }

    private onChangeAddon = (addon: Model.Addon, value: Model.AddonValue): void => {
        this.addonValueChange(addon, value);
        this.render();
        this.updateResult();
    }

    private findAddonResult(addons: Model.AddonResult[] | undefined, id: string): Model.AddonResult | undefined {
        if(addons) {
            for(const addon of addons) {
                if(addon.id === id) {
                    return addon;
                }
            }
        }
        return undefined;
    }

    private fieldValueChange(value: Model.FieldValue): void {
        this.fieldValues[value.id] = value;
        this.validate();
    }

    private onFieldValueChange = (value: Model.FieldValue, force?: boolean): void => {
        if (
            this.fieldValues[value.id] &&
            this.fieldValues[value.id].value === value.value
        ) {
            return;
        }

        this.fieldValueChange(value);
        this.render();
        if (force) {
            this.updateResult();
        } else {
            this.debouncedUpdateResult();
        }
    }

    private onChangeField = (field: Model.Field, value: Model.FieldValue): void => {
        if (
            this.fieldValues[value.id] &&
            this.fieldValues[value.id].value === value.value
        ) {
            return;
        }

        this.fieldValueChange(value);
        this.render();
        this.debouncedUpdateResult();
    }

    private findField(fields: Model.Field[] | undefined, id: string): Model.Field | undefined {
        if(fields) {
            for(const field of fields) {
                if(field.id === id) {
                    return field;
                }
            }
        }
        return undefined;
    }

    private updateAddonValues(): void {
        const addonValues: Model.AddonValueMap = {};
        const addonDisabled: Model.AddonDisableMap = {};
        let foundAddons = false;
        for(let level = 0; level < MAX_SUB_LEVELS; level++) {
            const option = this.selectedOption(level);
            if(!option) {
                break;
            }
            if(option.addons) {
                for(const addon of option.addons) {
                    let addonValue = this.addonValues[addon.id];
                    let fieldValues: Model.FieldValueMap = {};
                    let foundFields = false;
                    if(addon.fields) {
                        for(const field of addon.fields) {
                            let fieldValue: Model.FieldValue | undefined = undefined;
                            if(addonValue && addonValue.fields) {
                                fieldValue = addonValue.fields[field.id];
                            }
                            if(!fieldValue && field.initialValue) {
                                fieldValue = Model.buildFieldValue(field, undefined, { value: field.initialValue });
                                fieldValues[field.id] = fieldValue;
                                foundFields = true;
                            } else if(!fieldValue && field.items && (field.items.length > 0)) {
                                fieldValue = Model.buildFieldValue(field, undefined, { value: field.items[0].id });
                                fieldValues[field.id] = fieldValue;
                                foundFields = true;
                            }
                        }
                        if(foundFields) {
                            if(addonValue && addonValue.fields) {
                                fieldValues = { ...addonValue.fields, ...fieldValues };
                            }
                        } else {
                            if(addonValue) {
                                fieldValues = addonValue.fields;
                            }
                        }
                    }
                    if(!addonValue && (addon.selected || addon.mandatory) && !addonDisabled[addon.id]) {
                        addonValue = Model.buildAddonValue(addon, undefined, { selected: true, fields: fieldValues });
                        addonValues[addon.id] = addonValue;
                        foundAddons = true;
                    } else if(addonValue && addonValue.selected && addonDisabled[addon.id]) {
                        addonValue = Model.buildAddonValue(addon, addonValue, { selected: false, fields: fieldValues });
                        addonValues[addon.id] = addonValue;
                        foundAddons = true;
                    } else if(foundFields) {
                        addonValue = Model.buildAddonValue(addon, addonValue, { fields: fieldValues });
                        addonValues[addon.id] = addonValue;
                        foundAddons = true;
                    }
                    if(addonValue && addonValue.selected && addon.exclude) {
                        for(const addonId of addon.exclude) {
                            addonDisabled[addonId] = true;
                        }
                    }
                }
                for(const addon of option.addons) {
                    if(addon.oneOf && (addon.oneOf.length > 0)) {
                        let selectedId = undefined;
                        for(const addonId of addon.oneOf) {
                            const addonValue = addonValues[addonId] || this.addonValues[addonId];
                            if(addonValue && addonValue.selected && !selectedId) {
                                selectedId = addonId;
                            }
                        }
                        if(!selectedId) {
                            selectedId = addon.oneOf[0];
                            const addonValue = addonValues[selectedId] || this.addonValues[selectedId];
                            if((!addonValue || !addonValue.selected) && selectedId === addon.id) {
                                addonValues[selectedId] = Model.buildAddonValue(addon, addonValue, {
                                    selected: true
                                });
                                foundAddons = true;
                            }
                        }
                        for(const addonId of addon.oneOf) {
                            const addonValue = addonValues[addonId] || this.addonValues[addonId];
                            if(addonValue && addonValue.selected && (addonId !== selectedId)) {
                                addonValues[addonId] = {
                                    ...addonValue,
                                    selected: false
                                };
                                foundAddons = true;
                            }
                        }
                    }
                }
            }
        }
        if(foundAddons) {
            this.addonValues = {
                ...this.addonValues,
                ...addonValues
            };
        }
    }

    private updateAddonDisabled(): void {
        this.addonDisabled = {};
        for(let level = 0; level < MAX_SUB_LEVELS; level++) {
            const option = this.selectedOption(level);
            if(!option) {
                break;
            }
            if(option.addons) {
                for(const addon of option.addons) {
                    const addonValue = this.addonValues[addon.id];
                    if(addonValue && addonValue.selected && addon.exclude) {
                        for(const addonId of addon.exclude) {
                            this.addonDisabled[addonId] = true;
                        }
                    }
                    if(addon.dependency) {
                        for(const addonId of addon.dependency) {
                            if(addonId === 'FCPICKUPPOINT') {
                                // Check if agent map is empty
                                if(!this.selectedAgentIds[option.id] || this.selectedAgentIds[option.id].length === 0) {
                                    this.addonDisabled[addon.id] = true;

                                }
                            } else {
                                const addonDependencyValue = this.addonValues[addonId];
                                if(!addonDependencyValue || !addonDependencyValue.selected) {
                                    this.addonDisabled[addon.id] = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private updateFieldValues(): void {
        const fieldValues: Model.FieldValueMap = {};
        let foundFields = false;
        for(let level = 0; level < MAX_SUB_LEVELS; level++) {
            const option = this.selectedOption(level);
            if(!option) {
                break;
            }
            if(option.fields) {
                for(const field of option.fields) {
                    const fieldValue = this.fieldValues[field.id];
                    if(!fieldValue && field.initialValue) {
                        fieldValues[field.id] = Model.buildFieldValue(field, undefined, { value: field.initialValue });
                        foundFields = true;
                    } else if(!fieldValue && field.items && (field.items.length > 0)) {
                        fieldValues[field.id] = Model.buildFieldValue(field, undefined, { value: field.items[0].id });
                        foundFields = true;
                    }
                }
            }
        }
        if(foundFields) {
            this.fieldValues = {
                ...this.fieldValues,
                ...fieldValues
            };
        }
    }

    private validate(): void {
        // validate addons which has values
        const result: Model.ValidationResult = {
            addons: {},
            fields: {},
            customFields: {},
            agents: {},
            count: 0
        };

        for(let level = 0; level < MAX_SUB_LEVELS; level++) {
            const option = this.selectedOption(level);
            if(!option) {
                break;
            }

            const addonsValidation = validateAddons(option.addons || [], this.addonValues);
            const fieldsValidation = validateFields(option.fields || [], this.fieldValues);
            const agentsValidation = validateAgents(option, !!option.noDefaultAgent, this.selectedAgentIds[option.id]);

            result.addons = addonsValidation.result;
            result.fields = fieldsValidation.result;
            result.agents = agentsValidation.result;
            result.count += addonsValidation.count + fieldsValidation.count + agentsValidation.count;
        }

        // top level stuff
        const topLevelAddons = validateAddons(this.model.addons || [], this.addonValues);
        const customfieldsValidation = validateFields(this.model.fields || [], this.fieldValues);

        result.addons = {
            ...result.addons,
            ...topLevelAddons.result
        };
        result.count += topLevelAddons.count;
        result.customFields = customfieldsValidation.result;
        result.count += customfieldsValidation.count;

        this.validationResult = result;
    }
}

export function createAt(elemOrSelector: string | Element, configuration?: Model.Configuration): UnifaunCheckoutWidget {
    let rootContainerElement: Element | null = null;
    if(typeof elemOrSelector === 'string') {
        rootContainerElement = document.querySelector(elemOrSelector);
    } else {
        rootContainerElement = elemOrSelector;
    }
    const result = new UnifaunCheckoutWidget(rootContainerElement, configuration);
    result.render();

    return result;
}
