
      var exported = {};

      import API from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js";
      
      import setAttributes from "!../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      
      import content, * as namedExport from "!!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../node_modules/postcss-loader/dist/cjs.js!./nshift.css";
      
      if (content && content.locals) {
              exported.locals = content.locals;
            }
            

var refs = 0;
var update;
var options = {};

;
options.setAttributes = setAttributes;
options.insert = function insertAtTop(element) {
	var parent = document.querySelector("head");
	// eslint-disable-next-line no-underscore-dangle
	var lastInsertedElement =
		window._lastElementInsertedByStyleLoader;

	if (!lastInsertedElement) {
		parent.insertBefore(element, parent.firstChild);
	} else if (lastInsertedElement.nextSibling) {
		parent.insertBefore(element, lastInsertedElement.nextSibling);
	} else {
		parent.appendChild(element);
	}

	// eslint-disable-next-line no-underscore-dangle
	window._lastElementInsertedByStyleLoader = element;
};
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

exported.use = function(insertOptions) {
  options.options = insertOptions || {};

  if (!(refs++)) {
    update = API(content, options);
  }

  return exported;
};
exported.unuse = function() {
  if (refs > 0 && !--refs) {
    update();
    update = null;
  }
};



export * from "!!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../node_modules/postcss-loader/dist/cjs.js!./nshift.css";
       export default exported;
