import {
    Addon,
    Field,
    Option,
    Root,
    AddonValueMap,
    FieldValueMap,
    ValidationResult,
    AddonValue,
    FieldValue,
    IdMap,
    levelKey
} from '../../v1/model';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import translatedTexts from '../../v1/translated-texts';
import { AgentWithOptionId } from '../utils/internal-types';
import { useResizeManager } from '../utils/resize-manager';
import { AddonItem } from './addon-item';
import { FieldItem } from './field-item';
import { OptionItem } from './option-item';
import PopupContainerProvider from 'v2/utils/popup-container';
// tslint:disable-next-line: no-var-requires
const nshiftLogo = require('v2/svg/nshift.svg');

export interface Props {
    theme: string;
    options: Root | undefined;
    addonValues: AddonValueMap;
    fieldValues: FieldValueMap;
    validationResult: ValidationResult;
    selectedOptionIds: IdMap;
    selectedAgentIds: IdMap;
    breakpoints: [string, number][];
    mapEnabled: boolean;
    popupContainerElement?: HTMLElement;
    iconsBaseUrl: string;
    disabled: boolean;
    onResize?: (size: { w: number, h: number }) => void;
    onChangeOption: (level: number, parentId: string, option: Option) => void;
    onChangePickupPoint: (pickupPoint: AgentWithOptionId) => void;
    onChangeAddon: (addon: Addon, addonState: AddonValue) => void;
    onChangeField: (field: Field, fieldState: FieldValue) => void;
}

export function WidgetRoot(props: Props): JSX.Element {
    const {
        theme,
        options,
        addonValues,
        fieldValues,
        validationResult,
        selectedOptionIds,
        selectedAgentIds,
        breakpoints,
        mapEnabled,
        popupContainerElement,
        iconsBaseUrl,
        disabled,
        onResize,
        onChangeOption,
        onChangePickupPoint,
        onChangeAddon,
        onChangeField,
    } = props;
    const rootElement = useRef<HTMLDivElement>(null);
    const resizeManager = useResizeManager();
    const [width, setWidth] = useState<number>(0);
    useEffect(() => {
        if(rootElement.current) {
            return resizeManager.add(rootElement.current, (size) => {
                if(onResize) {
                    onResize(size);
                }
                setWidth(size.w);
            });
        }
        return undefined;
    }, [onResize]);
    const items = buildItems(
        '',
        options?.options ?? [],
        addonValues,
        fieldValues,
        validationResult,
        selectedOptionIds,
        selectedAgentIds,
        mapEnabled,
        popupContainerElement,
        iconsBaseUrl,
        disabled,
        onChangeOption,
        onChangePickupPoint,
        onChangeAddon,
        onChangeField
    );
    const breakpoint = breakpoints.find((item) => width < item[1]);
    return (
        <PopupContainerProvider value={ popupContainerElement }>
            <div className={ `nshift-checkout-widget ${theme} nshift-breakpoint-${breakpoint ? breakpoint[0] : 'wide'}` } ref={ rootElement }>
                <ul>
                    { items }
                </ul>
                { options && ((options.addons && (options.addons.length > 0)) || (options.fields && (options.fields.length > 0))) ? (
                    <div className="nshift-widget-extra">
                        { options && options.addons && (options.addons.length > 0) ? (
                            <ul className="nshift-widget-addons">
                                { options.addons.map((addon) => (
                                    <AddonItem
                                        key={ addon.id }
                                        sessionId=""
                                        option={ undefined }
                                        addon={ addon }
                                        addonState={ addonValues[addon.id] }
                                        disabled={ disabled }
                                        validationResult={ validationResult.addons[addon.id] }
                                        onChange={ onChangeAddon }
                                    />
                                )) }
                            </ul>
                        ) : null }
                        { options && options.fields && (options.fields.length > 0) ? (
                            <div className="nshift-widget-fields">
                                <div className="nshift-widget-fields-title">{ translatedTexts.texts.fieldsLabel }</div>
                                { options.fields.map((field) => (
                                    <FieldItem
                                        key={ field.id }
                                        idPrefix=""
                                        field={ field }
                                        fieldState={ fieldValues[field.id] }
                                        disabled={ disabled }
                                        validationResult={ validationResult.customFields[field.id] }
                                        onChange={ onChangeField }
                                    />
                                )) }
                            </div>
                        ) : null }
                    </div>
                ) : null }
                <div className="nshift-branding">
                    <span className="nshift-branding-text">powered by</span>
                    { /* eslint-disable-next-line react/no-danger */ }
                    <span className="nshift-branding-logo" dangerouslySetInnerHTML={ { __html: nshiftLogo } } />
                </div>
            </div>
        </PopupContainerProvider>
    );
}

function buildItems(
    sessionId: string,
    options: Option[],
    addonValues: AddonValueMap,
    fieldValues: FieldValueMap,
    validationResult: ValidationResult,
    selectedOptionIds: IdMap,
    selectedAgentIds: IdMap,
    mapEnabled: boolean,
    popupContainerElement: Element | undefined,
    iconsBaseUrl: string,
    disabled: boolean,
    onChangeOption: (level: number, parentId: string, option: Option) => void,
    onChangePickupPoint: (pickupPoint: AgentWithOptionId) => void,
    onChangeAddon: (addon: Addon, addonState: AddonValue) => void,
    onChangeField: (field: Field, fieldState: FieldValue) => void
): JSX.Element[] {
    return options.flatMap((option) => {
        if(option.subOptions && (option.subOptions.length > 0)) {
            return option.subOptions.map((subOption) => (
                <OptionItem
                    key={ subOption.id }
                    sessionId={ sessionId }
                    option={ subOption }
                    addonValues={ addonValues }
                    fieldValues={ fieldValues }
                    validationResult={ validationResult }
                    agentId={ selectedAgentIds[subOption.id] }
                    level={ 1 }
                    parentId={ option.id }
                    selected={ isSelected(1, subOption, selectedOptionIds) }
                    disabled={ disabled }
                    mapEnabled={ mapEnabled }
                    iconsBaseUrl={ iconsBaseUrl }
                    onChange={ onChangeOption }
                    onChangePickupPoint={ onChangePickupPoint }
                    onChangeAddon={ onChangeAddon }
                    onChangeField={ onChangeField }
                />
            ));
        }
        return [<OptionItem
            key={ option.id }
            sessionId={ sessionId }
            option={ option }
            addonValues={ addonValues }
            fieldValues={ fieldValues }
            validationResult={ validationResult }
            agentId={ selectedAgentIds[option.id] }
            level={ 0 }
            parentId="$"
            selected={ isSelected(0, option, selectedOptionIds) }
            disabled={ disabled }
            mapEnabled={ mapEnabled }
            iconsBaseUrl={ iconsBaseUrl }
            onChange={ onChangeOption }
            onChangePickupPoint={ onChangePickupPoint }
            onChangeAddon={ onChangeAddon }
            onChangeField={ onChangeField }
        />];
    });
}

function isSelected(level: number, option: Option, selectedOptionIds: IdMap): boolean {
    return !!selectedOptionIds && (option.id === selectedOptionIds[levelKey(level)]);
}
