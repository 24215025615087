import * as React from 'react';

export interface Props {
    id: string;
    name: string;
    value: string;
    checked: boolean;
    disabled: boolean;
    onChange: (value: string) => void;
    onFocus?: (id: string, value: string) => void;
}

export function InputRadio(props: Props): JSX.Element {
    const {
        id,
        name,
        value,
        checked,
        disabled,
        onChange,
        onFocus
    } = props;
    function onChangeCallback(): void {
        onChange(value);
    }
    function onFocusCallback(): void {
        if(onFocus) {
            onFocus(id, value);
        }
    }
    return (
        <span className={ `nshift-input-radio ${checked ? 'nshift-checked' : ''} ${disabled ? 'nshift-disabled' : 'nshift-enabled'}` }>
            <input
                type="radio"
                id={ id }
                name={ name }
                value={ value }
                checked={ checked }
                disabled={ disabled }
                className="nshift-input-radio-control"
                onChange={ onChangeCallback }
                onFocus={ onFocusCallback }
            />
            <span className="nshift-input-radio-overlay1">
                <span className="nshift-input-radio-overlay2" />
            </span>
        </span>
    );
}
