import * as React from 'react';
import TranslatedTexts from '../translated-texts';
import * as Model from '../model';

export interface ListFieldProps {
    option?: Model.Option;
    field: Model.Field;
    value?: Model.FieldValue;
    active: boolean;
    disabled: boolean;
    validationResult?: Model.ValidationFieldResult;
    onChange: (value: Model.FieldValue) => void;
}

export default class ListField extends React.Component<ListFieldProps, {}> {

    private inputElement: HTMLElement | null;

    public render() {
        const option = this.props.option;
        const field = this.props.field;
        const id = (option && option.id || '') + '$' + field.id;
        let message = undefined;
        if(this.props.validationResult) {
            message = this.props.validationResult.message;
        }
        let className = '';
        if(this.props.disabled) {
            className = className + ' unifaun-checkout-disabled';
        }
        if(message) {
            className = className + ' unifaun-checkout-invalid';
        }

        return (
            <div className="unifaun-checkout-option-field">
                <span className="unifaun-checkout-option-field-content unifaun-checkout-valign">
                    <span className={ 'unifaun-checkout-list-input' + className } onMouseDown={ this.onClick }>
                        <label id={ id + '$Lbl' } htmlFor={ id } className="unifaun-checkout-list-input-label" onMouseDown={ this.onClick }>
                            { field.description }
                        </label>
                        <span id={ field.id + '$Msg' } className="unifaun-checkout-list-input-label-message">{ message }</span>
                        <select
                            ref={ (elem) => { this.inputElement = elem; } }
                            id={ id }
                            className="unifaun-checkout-list-input-select"
                            value={ this.props.value ? this.props.value.value : '' }
                            disabled={ this.props.disabled }
                            aria-labelledby={ id + '$Lbl ' + id + '$Msg' }
                            onChange={ this.onChange }>
                            { this.props.value && this.props.value.value ? null : <option key="empty" value="">{TranslatedTexts.texts.chooseLabel}</option> }
                            { this.renderItems(field) }
                        </select>
                    </span>
                </span>
            </div>
        );
    }

    private renderItems(field: Model.Field) {
        if(!field || !field.items) {
            return null;
        }
        return field.items.map((item) => {
            return (
                <option key={ item.id } value={ item.id }>{ item.description }</option>
            );
        });
    }

    private onChange = (event: React.SyntheticEvent<HTMLSelectElement>) => {
        const value = event.currentTarget.value;
        this.props.onChange(Model.buildFieldValue(this.props.field, this.props.value, { value: value }));
    }

    private onClick = (event: React.MouseEvent<any>) => {
        if(this.inputElement) {
            this.inputElement.focus();
        }
    }
}
