import * as React from 'react';
import type {
    DivIcon,
    LeafletKeyboardEvent,
    Map,
    Marker,
} from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { Agent, } from '../../v1/model';
import { useResizeManager } from '../utils/resize-manager';
import { AgentWithOptionId } from '../utils/internal-types';
// tslint:disable-next-line: no-var-requires
const locationIcon = require('v2/svg/location.svg');

let selectedLocationIcon: DivIcon | undefined;
let primaryLocationIcon: DivIcon | undefined;
let secondaryLocationIcon: DivIcon | undefined;

type Leaflet = typeof import('leaflet');

interface MarkerMap {
    [optionAndPickupPointId: string]: MarkerInfo;
}

interface MarkerInfo {
    pickupPoint: AgentWithOptionId;
    marker: Marker;
}

export interface Props {
    primaryPickupPoints: AgentWithOptionId[];
    secondaryPickupPoints: AgentWithOptionId[];
    selectedOptionId: string;
    selectedPickupPointId: string;
    disabled: boolean;
    onSelect: (pickupPoint: AgentWithOptionId) => void;
}

function InternalPickupPointsMap(props: Props): JSX.Element {
    const {
        primaryPickupPoints,
        secondaryPickupPoints,
        selectedOptionId,
        selectedPickupPointId,
        disabled,
        onSelect,
    } = props;
    const [leaflet, setLeaflet] = useState<Leaflet | undefined>(undefined);
    const [leafletMap, setLeafletMap] = useState<Map | undefined>(undefined);
    const [leafletMarkers, setLeafletMarkers] = useState<MarkerMap>({});
    const containerRef = useRef<HTMLDivElement>(null);
    const resizeManager = useResizeManager();
    useEffect(() => {
        if(containerRef.current && leafletMap) {
            return resizeManager.add(containerRef.current, () => {
                leafletMap.invalidateSize(true);
            });
        }
        return undefined;
    }, [containerRef.current, leafletMap]);
    useEffect(() => {
        import(/* webpackChunkName: "leaflet" */ 'leaflet').then((module) => {
            if(!selectedLocationIcon) {
                selectedLocationIcon = module.divIcon({
                    html: locationIcon,
                    className: 'nshift-location-selected',
                    iconSize: [42, 42],
                    iconAnchor: [21, 42],
                    popupAnchor: [0, -42]
                });
            }
            if(!primaryLocationIcon) {
                primaryLocationIcon = module.divIcon({
                    html: locationIcon,
                    className: 'nshift-location-primary',
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                    popupAnchor: [0, -32]
                });
            }
            if(!secondaryLocationIcon) {
                secondaryLocationIcon = module.divIcon({
                    html: locationIcon,
                    className: 'nshift-location-secondary',
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                    popupAnchor: [0, -32]
                });
            }
            setLeaflet(module);
        });
    }, []);
    useEffect(() => {
        if(containerRef.current && leaflet) {
            const tmp = leaflet.map(containerRef.current);
            leaflet.tileLayer(
                'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
            ).addTo(tmp);
            tmp.setView([0, 0], 14);
            setLeafletMap(tmp);
            return () => {
                tmp.off();
                tmp.remove();
            };
        }
        return undefined;
    }, [containerRef.current, leaflet]);
    useEffect(() => {
        const markers: MarkerMap = {};
        if(leaflet && leafletMap && !disabled) {
            primaryPickupPoints.map((pickupPoint) => {
                const marker = leaflet.marker(
                    [pickupPoint.mapLatitude ?? 0, pickupPoint.mapLongitude ?? 0],
                    { icon: primaryLocationIcon }
                ).addTo(leafletMap);
                return {
                    pickupPoint,
                    marker,
                } as MarkerInfo;
            }).concat(secondaryPickupPoints.map((pickupPoint) => {
                const marker = leaflet.marker(
                    [pickupPoint.mapLatitude ?? 0, pickupPoint.mapLongitude ?? 0],
                    { icon: secondaryLocationIcon }
                ).addTo(leafletMap);
                return {
                    pickupPoint,
                    marker,
                } as MarkerInfo;
            })).forEach((markerInfo) => {
                markers[`${markerInfo.pickupPoint.optionId}${markerInfo.pickupPoint.id}`] = markerInfo;
            });
            setLeafletMarkers(markers);
        } else {
            setLeafletMarkers({});
        }
        return () => {
            Object.values(markers).forEach((markerInfo) => {
                markerInfo.marker.off();
                markerInfo.marker.remove();
            });
        };
    }, [leaflet, leafletMap, disabled, primaryPickupPoints, secondaryPickupPoints]);
    useEffect(() => {
        if(leafletMap && leafletMarkers && selectedOptionId && selectedPickupPointId) {
            const markerInfo = leafletMarkers[`${selectedOptionId}${selectedPickupPointId}`];
            if(markerInfo) {
                leafletMap.setView(markerInfo.marker.getLatLng(), 14);
            }
            Object.values(leafletMarkers).forEach((info) => {
                if(selectedLocationIcon && primaryLocationIcon) {
                    info.marker.setIcon(info.pickupPoint.id === selectedPickupPointId ? selectedLocationIcon : primaryLocationIcon);
                }
            });
        }
    }, [leafletMap, leafletMarkers, selectedOptionId, selectedPickupPointId]);
    return (
        <div className="nshift-map">
            <div ref={ containerRef }>
                { Object.values(leafletMarkers).map((markerInfo) => (
                    <PickupPointMarker
                        key={ markerInfo.pickupPoint.id }
                        marker={ markerInfo.marker }
                        pickupPoint={ markerInfo.pickupPoint }
                        onClick={ onSelect }
                    />
                )) }
            </div>
            { disabled ? <div className="nshift-map-overlay" /> : null }
        </div>
    );
}

export const PickupPointsMap = React.memo(InternalPickupPointsMap);

interface PickupPointMarkerProps {
    marker: Marker;
    pickupPoint: Agent;
    onClick: (pickupPoint: Agent) => void;
}

function PickupPointMarker(props: PickupPointMarkerProps): JSX.Element | null {
    const {
        marker,
        pickupPoint,
        onClick,
    } = props;
    function onMarkerClickCallback(): void {
        onClick(pickupPoint);
    }
    function onMarkerKeypressCallback(e: LeafletKeyboardEvent): void {
        if(e.originalEvent.key === 'Enter') {
            onClick(pickupPoint);
        }
    }
    useEffect(() => {
        marker.on('click', onMarkerClickCallback);
        marker.on('keypress', onMarkerKeypressCallback);
        return () => {
            marker.off('keypress', onMarkerKeypressCallback);
            marker.off('click', onMarkerClickCallback);
        };
    }, [marker]);
    return null;
}
