import * as React from 'react';
import * as Model from '../model';
import TextInput from './text-input';

export interface TextFieldProps {
    option?: Model.Option;
    field: Model.Field;
    value?: Model.FieldValue;
    active: boolean;
    disabled: boolean;
    validationResult?: Model.ValidationFieldResult;
    onChange: (value: Model.FieldValue) => void;
    onBlur: () => void;
    theme: string;
}

export default class TextField extends React.Component<TextFieldProps, {}> {

    public render() {
        const option = this.props.option;
        const field = this.props.field;
        let message = undefined;
        if(this.props.validationResult) {
            message = this.props.validationResult.message;
        }
        return (
            <div className="unifaun-checkout-option-field">
                <span className="unifaun-checkout-option-field-content unifaun-checkout-valign">
                    <TextInput
                        id={ (option && option.id || '') + '$' + field.id }
                        caption={ field.description }
                        value={ this.props.value ? this.props.value.value : '' }
                        disabled={ this.props.disabled }
                        message={ message }
                        onChange={ this.onChange }
                        onBlur={ this.props.onBlur }
                        theme={this.props.theme}
                        />
                </span>
            </div>
        );
    }

    private onChange = (value: string) => {
        this.props.onChange(Model.buildFieldValue(this.props.field, this.props.value, { value: value }));
    }

}
