import * as React from 'react';
import { useState } from 'react';

export interface Props {
    url: string | undefined;
    logoId: string;
}

export function LogoImage(props: Props): JSX.Element {
    const {
        url,
        logoId,
    } = props;
    const [loaded, setLoaded] = useState(false);
    function onLoadCallback(): void {
        setLoaded(true);
    }
    return (
        <span
            className={ `nshift-logo-image ${loaded ? 'nshift-loaded' : 'nshift-unloaded'}` }
        >
            <img
                src={ url }
                onLoad={ onLoadCallback }
                alt={ logoId }
            />
        </span>
    );
}
