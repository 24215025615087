import TranslatedTexts from './translated-texts';
import * as Model from './model';

interface ValidationResultMap<M> {
    result: M;
    count: number;
}

export function validateAddons(addons: Model.Addon[], valueMap: Model.AddonValueMap): ValidationResultMap<Model.ValidationAddonResultMap> {
    const validation: ValidationResultMap<Model.ValidationAddonResultMap> = {
        result: {},
        count: 0
    };

    for (const addon of addons) {
        const addonValue = valueMap[addon.id];
        const addonResult = validateAddon(addon, addonValue, valueMap);

        validation.count += addonResult.count;
        validation.result[addon.id] = addonResult;
    }

    return validation;
}

export function validateFields(fields: Model.Field[], valueMap: Model.FieldValueMap): ValidationResultMap<Model.ValidationFieldResultMap> {
    const validation: ValidationResultMap<Model.ValidationFieldResultMap> = {
        result: {},
        count: 0
    };

    for (const field of fields) {
        const fieldValue = valueMap[field.id];
        const fieldResult = validateField(field, fieldValue);

        if (fieldResult) {
            validation.count += 1;
            validation.result[field.id] = fieldResult;
        }
    }

    return validation;
}

export function validateAgents(option: Model.Option, noDefaultAgent: boolean, selectedAgentIds: string) {
    const validation: ValidationResultMap<Model.ValidationAgentResultMap> = {
        result: {},
        count: 0
    };
    if(
        option.agents &&
        option.agents.length > 0 &&
        noDefaultAgent &&
        !selectedAgentIds
    ) {
        validation.count += 1;
        validation.result[option.id] = {
            message: TranslatedTexts.texts.mandatoryAgent,
        };
    }

    return validation;
}

function validateAddon(addon: Model.Addon, addonValue: Model.AddonValue, valueMap: Model.AddonValueMap) {
    const validation: Model.ValidationAddonResult = {
        fields: {},
        count: 0,
    };

    if (!checkAtleastOneOf(addon.atLeastOneOf, valueMap)) {
        validation.count += 1;
        validation.message = TranslatedTexts.texts.selectOneOrMoreAddon;
    }

    if (addonValue && addonValue.selected && addon.fields) {
        const { result: fieldResult, count: fieldCount } = validateFields(addon.fields, addonValue.fields);
        validation.count += fieldCount;
        validation.fields = fieldResult;
    }

    return validation;
}

function validateField(field: Model.Field, fieldValue: Model.FieldValue): Model.ValidationFieldResult | undefined {
    const value = (fieldValue && fieldValue.value && fieldValue.value.trim()) || '';
    if (field.mandatory && !value) {
        return {
            message: TranslatedTexts.texts.mandatoryField
        };
    } else if (!field.mandatory && !value) {
        // optional and no value is always fine!
        return undefined;
    }

    switch(field.type) {
        case Model.FIELD_TYPE_EMAIL:
            if(!Model.EMAIL_REGEXP.test(value)) {
                return {
                    message: TranslatedTexts.texts.emailField
                };
            }
            break;
        case Model.FIELD_TYPE_PHONE:
            if(!Model.PHONE_REGEXP.test(value)) {
                return {
                    message: TranslatedTexts.texts.phoneField
                };
            }
            break;
        case Model.FIELD_TYPE_DIGITS:
            if(!Model.DIGITS_REGEXP.test(value)) {
                return {
                    message: TranslatedTexts.texts.digitsField
                };
            }
            break;
        case Model.FIELD_TYPE_NUMBER:
            let num = parseFloat(value);
            if(isNaN(num) || !isFinite(num)) {
                num = parseFloat(value.replace(',', '.'));
            }
            if(!Model.NUMBER_REGEXP.test(value) || isNaN(num) || !isFinite(num)) {
                return {
                    message: TranslatedTexts.texts.numberField
                };
            }
            if(field.min && (num < field.min)) {
                return {
                    message: TranslatedTexts.texts.minValueField
                };
            }
            if(field.max && (num > field.max)) {
                return {
                    message: TranslatedTexts.texts.maxValueField
                };
            }
            break;
        default:
            break;
    }
    if(field.type !== Model.FIELD_TYPE_NUMBER) {
        if(field.min && (value.length < field.min)) {
            return {
                message: TranslatedTexts.texts.minLengthField
            };
        }
        if(field.max && (value.length > field.max)) {
            return {
                message: TranslatedTexts.texts.maxLengthField
            };
        }
        if(field.pattern) {
            const re = new RegExp(field.pattern);
            if(value && !re.test(value)) {
                return {
                    message: TranslatedTexts.texts.patternField
                };
            }
        }
    }
    return undefined;
}

function checkAtleastOneOf(atLeastOneOf: string[] | undefined, valueMap: Model.AddonValueMap) {
    if (!atLeastOneOf || atLeastOneOf.length === 0) {
        return true;
    }

    return atLeastOneOf.some((id) => {
        const addonValue = valueMap[id];

        return addonValue !== undefined && addonValue.selected === true;
    });
}
