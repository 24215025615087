import * as React from 'react';
import * as Model from '../model';
import TextField from './text-field';
import SelectField from './list-field';

export interface FieldProps {
    option?: Model.Option;
    addon?: Model.AddonValue;
    field: Model.Field;
    value?: Model.FieldValue;
    active: boolean;
    disabled: boolean;
    validationResult?: Model.ValidationFieldResult;
    theme: string;
    onFieldValueChange?: (field: Model.FieldValue, force?: boolean) => void;
    onAddonFieldValueChange?: (addon: Model.AddonValue, field: Model.FieldValue) => void;
}

export default class Field extends React.Component<FieldProps, {}> {

    public render() {
        const field = this.props.field;
        if(field.items && (field.items.length > 0)) {
            return (
                <SelectField
                    option={ this.props.option }
                    field={ this.props.field }
                    value={ this.props.value }
                    active={ this.props.active }
                    disabled={ this.props.disabled }
                    validationResult={ this.props.validationResult }
                    onChange={ this.onTextChange }/>
            );
        } else {
            return (
                <TextField
                    option={ this.props.option }
                    field={ this.props.field }
                    value={ this.props.value }
                    active={ this.props.active }
                    disabled={ this.props.disabled }
                    validationResult={ this.props.validationResult }
                    onBlur={ this.onBlur }
                    onChange={ this.onTextChange }
                    theme={ this.props.theme }/>
            );
        }
    }

    private onTextChange = (value: Model.FieldValue) => {
        if(this.props.addon && this.props.onAddonFieldValueChange) {
            this.props.onAddonFieldValueChange(this.props.addon, value);
        } else if(this.props.onFieldValueChange) {
            this.props.onFieldValueChange(value);
        }
    }

    private onBlur = () => {
        if (this.props.onFieldValueChange && this.props.value) {
            this.props.onFieldValueChange(this.props.value, true);
        }
    }
}
