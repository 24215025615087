import {
    Addon,
    Field,
    FieldValue,
    AddonValue
} from '../../v1/model';

export function buildFieldState(field: Field, fieldState: FieldValue | undefined, change: Partial<FieldValue>): FieldValue {
    return {
        ...(fieldState ?? {
            id: field.id,
            value: ''
        }),
        ...change,
    };
}

export function buildAddonState(addon: Addon, addonState: AddonValue | undefined, change: Partial<AddonValue>): AddonValue {
    return {
        ...(addonState ?? {
            id: addon.id,
            fields: {},
            selected: !!(addon.mandatory || addon.selected || change.selected)
        }),
        ...change,
    };
}
