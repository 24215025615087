import * as React from 'react';
import * as Model from '../model';
import Addon from './addon';

interface Props {
    option?: Model.Option;
    addons: Model.Addon[] | undefined;
    disabled: boolean;
    addonDisabled: Model.AddonDisableMap;
    validationResult: Model.ValidationResult;
    addonValues: Model.AddonValueMap;
    level?: number;
    selectedOptionIds?: Model.IdMap;
    theme: string;
    onAddonValueChange: (addon: Model.Addon, value: Model.AddonValue) => void;
}

export default class Addons extends React.PureComponent<Props, {}> {
    private isSelected(option: Model.Option | undefined): boolean {
        if (!option) {
            return true;
        }
        return !!this.props.selectedOptionIds && (option.id === this.props.selectedOptionIds[Model.levelKey(this.props.level || 0)]);
    }

    public render() {
        if(!this.props.addons) {
            return null;
        }
        let validationResults: Model.ValidationAddonResultMap | undefined = undefined;
        if(this.isSelected(this.props.option) && this.props.validationResult && this.props.validationResult.addons) {
            validationResults = this.props.validationResult.addons;
        }
        return (
            <div>
                {
                    this.props.addons.map((addon) => {
                        const value = this.props.addonValues[addon.id];
                        const disabled = (
                            this.props.disabled ||
                            !this.isSelected(this.props.option) ||
                            (this.props.addonDisabled[addon.id] && (!value || !value.selected))
                        );
                        let validationResult: Model.ValidationAddonResult | undefined = undefined;
                        if(validationResults) {
                            validationResult = validationResults[addon.id];
                        }
                        return (
                            <Addon
                                key={ (this.props.option || { id: 'global' }).id + '$' + addon.id + '$Addon' }
                                option={ this.props.option }
                                addon={ addon }
                                addonValue={ value }
                                disabled={ disabled }
                                validationResult={ validationResult }
                                onAddonValueChange={ this.props.onAddonValueChange }
                                theme={ this.props.theme }/>
                        );
                    })
                }
            </div>
        );
    }
}
