import * as React from 'react';
import { Field, FieldValue, ValidationFieldResult } from '../../v1/model';
import { useCallback } from 'react';
import { buildFieldState } from '../utils/internal-state';
import { ListItem } from '../utils/internal-types';
import { InputDropDown } from './input-dropdown';
import { InputText } from './input-text';

export interface Props {
    idPrefix: string;
    field: Field;
    fieldState: FieldValue | undefined;
    disabled: boolean;
    validationResult?: ValidationFieldResult;
    onChange: (field: Field, fieldState: FieldValue) => void;
}

export function FieldItem(props: Props): JSX.Element {
    const {
        idPrefix,
        field,
        fieldState,
        disabled,
        validationResult,
        onChange,
    } = props;
    const onChangeCallback = useCallback((value: string) => {
        onChange(field, buildFieldState(field, fieldState, { value }));
    }, [field, fieldState, onChange]);
    const items = React.useMemo<ListItem[]>(() => field.items?.map((item) => ({
        name: item.description,
        value: item.id,
    })) ?? [], [field.items]);
    return (
        <div className="nshift-field">
            { field.items && (field.items.length > 0) ? (
                <InputDropDown
                    id={ `${idPrefix}Field${field.id}` }
                    name={ `${idPrefix}Field${field.id}` }
                    label={ field.description }
                    value={ fieldState?.value ?? '' }
                    errorMessage={ validationResult?.message }
                    disabled={ disabled }
                    clearable={ false }
                    items={ items }
                    onFormatItem={ onFormatItem }
                    onChange={ onChangeCallback }
                />
            ) : (
                <InputText
                    id={ `${idPrefix}Field${field.id}` }
                    name={ `${idPrefix}Field${field.id}` }
                    value={ fieldState?.value ?? '' }
                    errorMessage={ validationResult?.message }
                    disabled={ disabled }
                    label={ field.description ?? '' }
                    onChange={ onChangeCallback }
                />
            ) }
        </div>
    );
}

function onFormatItem(item: ListItem): React.ReactChild {
    return (
        <span className="nshift-dropdown-item">{ item.name }</span>
    );
}
