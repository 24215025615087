import * as React from 'react';
import * as Model from '../model';
import Option from './option';
import Field from './field';
import Addons from './addons';

export interface RootProps {
    model?: Model.Root;
    selectedOptionIds?: Model.IdMap;
    selectedAgentIds?: Model.IdMap;
    animLevel: number;
    narrow: boolean;
    narrowBreakpointWidth: number;
    ultraNarrowBreakpointWidth?: number;
    addonValues: Model.AddonValueMap;
    fieldValues: Model.FieldValueMap;
    useIcons: boolean;
    iconsInFront: boolean;
    iconsBaseUrl: string;
    disabled: boolean;
    hideAgentInfo: boolean;
    addonDisabled: Model.AddonDisableMap;
    enableMap: boolean;
    validationResult: Model.ValidationResult;
    cssStyle: string;
    onOptionSelect: (level: number, parentId: string, option: Model.Option) => void;
    onAgentSelect: (option: Model.Option, agent: Model.Agent) => void;
    onAnimLevel: (level: number) => void;
    onAddonValueChange: (addon: Model.Addon, value: Model.AddonValue) => void;
    onFieldValueChange: (value: Model.FieldValue, force?: boolean) => void;
}

export interface RootState {
    narrow?: boolean;
    ultraNarrow?: boolean;
}

export const RESIZE_DELAY_MS = 100;

export default class Root extends React.Component<RootProps, RootState> {

    private resizeElem: HTMLElement | null;
    private resizeActive: boolean;
    private resizeTimerId: any;

    constructor(props: RootProps, context?: any) {
        super(props, context);
        this.state = {
            narrow: false,
            ultraNarrow: false
        };
    }

    public componentDidMount() {
        if(this.props.narrowBreakpointWidth > 0) {
            this.activateResizeTracking();
            this.onResize();
        }
    }

    public componentWillUnmount() {
        this.deactivateResizeTracking();
    }

    public componentWillReceiveProps(newProps: RootProps) {
        if(this.resizeActive && (newProps.narrowBreakpointWidth <= 0)) {
            this.deactivateResizeTracking();
        } else if(!this.resizeActive && (newProps.narrowBreakpointWidth > 0)) {
            this.activateResizeTracking();
        }
    }

    /*
    public componentDidUpdate(prevProps: RootProps) {
        if(this.props.model !== prevProps.model) {
            this.onResize();
        }
    }
    */

    public refreshResizeTracking() {
        if(this.resizeActive) {
            this.onResize();
        }
    }

    private activateResizeTracking() {
        if(!this.resizeActive) {
            if(window.addEventListener) {
                window.addEventListener('resize', this.onResize);
                this.resizeActive = true;
            } else if((window as any).attachEvent) {
                (window as any).attachEvent('onresize', this.onResize);
                this.resizeActive = true;
            }
        }
    }

    public deactivateResizeTracking() {
        if(this.resizeActive) {
            if(window.removeEventListener) {
                window.removeEventListener('resize', this.onResize);
            } else if((window as any).detachEvent) {
                (window as any).detachEvent('onresize', this.onResize);
            }
            this.resizeActive = false;
            this.onResize();
        }
    }

    private onResize = () => {
        if(this.resizeTimerId) {
            return;
        }
        this.resizeTimerId = setTimeout(() => {
            this.resizeTimerId = undefined;
            const width = this.resizeElem ? this.resizeElem.offsetWidth : 0;
            if(this.props.ultraNarrowBreakpointWidth && (width <= this.props.ultraNarrowBreakpointWidth) && !this.state.ultraNarrow) {
                this.setState({ narrow: true, ultraNarrow: true });
            } else if((width <= this.props.narrowBreakpointWidth) && !this.state.narrow) {
                this.setState({ narrow: true, ultraNarrow: false });
            } else if(this.props.ultraNarrowBreakpointWidth && (width > this.props.ultraNarrowBreakpointWidth) && this.state.ultraNarrow) {
                this.setState({ narrow: true, ultraNarrow: false });
            } else if((width > this.props.narrowBreakpointWidth) && this.state.narrow) {
                this.setState({ narrow: false, ultraNarrow: false });
            }
        }, RESIZE_DELAY_MS);
    }

    public render() {
        let className = '';
        if(this.state.narrow || this.props.narrow) {
            className = className + ' unifaun-checkout-narrow';
        }
        if(this.state.ultraNarrow) {
            className = className + ' unifaun-checkout-ultra-narrow';
        }
        if(this.props.disabled) {
            className = className + ' unifaun-checkout-disabled';
        }
        return (
            <div ref={ (elem) => { this.resizeElem = elem; } } className={ 'unifaun-checkout-root' + className }>
                { this.renderModel() }
            </div>
        );
    }

    private renderModel() {
        if(this.props.model) {
            return (
                <div>
                    <div>{ this.renderOptions(this.props.model.options) }</div>
                    <div className="unifaun-checkout-top-addons">
                        { this.renderAddons(this.props.model.addons) }
                    </div>
                    <div className="unifaun-checkout-additional-fields">
                        { this.renderFields(this.props.model.fields) }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderOptions(options: Model.Option[]) {
        const useIcon = this.props.useIcons && (options.filter((option) => !!option.carrierId).length > 0);
        return options.map((option) => {
            return (
                <Option
                    key={ option.id }
                    option={ option }
                    level={ 0 }
                    animLevel={ this.props.animLevel }
                    parentId="$"
                    hideAgentInfo={ this.props.hideAgentInfo }
                    selectedOptionIds={ this.props.selectedOptionIds }
                    selectedAgentIds={ this.props.selectedAgentIds }
                    narrow={ this.state.narrow || this.props.narrow }
                    ultraNarrow={ this.state.ultraNarrow || false }
                    addonValues={ this.props.addonValues }
                    fieldValues={ this.props.fieldValues }
                    displayIcon={ useIcon }
                    useIcons={ this.props.useIcons }
                    iconsInFront={ this.props.iconsInFront }
                    iconsBaseUrl={ this.props.iconsBaseUrl }
                    disabled={ this.props.disabled }
                    addonDisabled={ this.props.addonDisabled }
                    validationResult={ this.props.validationResult }
                    enableMap={this.props.enableMap}
                    cssStyle={this.props.cssStyle}
                    onOptionSelect={ this.props.onOptionSelect }
                    onAgentSelect={ this.props.onAgentSelect }
                    onAnimLevel={ this.props.onAnimLevel }
                    onAddonValueChange={ this.props.onAddonValueChange }
                    onFieldValueChange={ this.props.onFieldValueChange }/>
            );
        });
    }

    private renderAddons(addons: Model.Addon[] | undefined) {
        if (!addons || addons.length <= 0) {
            return null;
        }

        return (
            <Addons
                addons={addons}
                disabled={this.props.disabled}
                addonDisabled={this.props.addonDisabled}
                validationResult={this.props.validationResult}
                addonValues={this.props.addonValues}
                selectedOptionIds={this.props.selectedOptionIds}
                onAddonValueChange={this.props.onAddonValueChange}
                theme={this.props.cssStyle}
            />
        );
    }

    private renderFields(fields: Model.Field[] | undefined) {
        if(!fields || fields.length <= 0) {
            return null;
        }

        let validationResults: Model.ValidationFieldResultMap | undefined = undefined;
        if(this.props.validationResult && this.props.validationResult.customFields) {
            validationResults = this.props.validationResult.customFields;
        }

        return fields.map((field) => {
            const value = this.props.fieldValues[field.id];
            let validationResult: Model.ValidationFieldResult | undefined = undefined;
            if(validationResults) {
                validationResult = validationResults[field.id];
            }
            return (
                <Field
                    key={ field.id + '$Field' }
                    field={ field }
                    value={ value }
                    active={ true }
                    disabled={ this.props.disabled }
                    validationResult={ validationResult }
                    onFieldValueChange={ this.props.onFieldValueChange }
                    theme={ this.props.cssStyle }/>
            );
        });
    }

}
