import * as React from 'react';
import * as Model from '../model';
import * as ReactMotion from 'react-motion';
import TranslatedTexts from '../translated-texts';
import Addons from './addons';
import Field from './field';
import LogoImage from './logo-image';
import LeafletMap from './map';

export interface OptionProps {
    option: Model.Option;
    level: number;
    animLevel: number;
    parentId: string;
    selectedOptionIds?: Model.IdMap;
    selectedAgentIds?: Model.IdMap;
    narrow: boolean;
    ultraNarrow: boolean;
    addonValues: Model.AddonValueMap;
    fieldValues: Model.FieldValueMap;
    displayIcon: boolean;
    useIcons: boolean;
    iconsInFront: boolean;
    iconsBaseUrl: string;
    disabled: boolean;
    hideAgentInfo: boolean;
    addonDisabled: Model.AddonDisableMap;
    validationResult: Model.ValidationResult;
    enableMap: boolean;
    cssStyle: string;
    onOptionSelect: (level: number, parentId: string, option: Model.Option) => void;
    onAgentSelect: (option: Model.Option, agent: Model.Agent) => void;
    onAnimLevel: (level: number) => void;
    onAddonValueChange: (addon: Model.Addon, value: Model.AddonValue) => void;
    onFieldValueChange: (value: Model.FieldValue, force?: boolean) => void;
}

export interface OptionState {
    showInfo?: boolean;
    expanded?: boolean;
    activeAgent?: Model.Agent;
    mapCenter?: Model.Coordinate;
}

const MOTION_CONFIG = { ...ReactMotion.presets.stiff, precision: 1 };
const MOTION_ZERO_SPRING = ReactMotion.spring(0, MOTION_CONFIG);

export default class Option extends React.Component<OptionProps, OptionState> {

    private subOptionsElem: HTMLElement | null;
    private agentInfoElem: HTMLElement | null;
    private height: number = 0;

    constructor(props: OptionProps) {
        super(props);
        this.state =  {
            activeAgent: this.selectedAgent(),
            mapCenter: undefined,
            showInfo: false,
            expanded: false
        };
    }

    public componentDidMount() {
        this.measureSubOptions();
    }

    public componentDidUpdate(prevProps: OptionProps, prevState: OptionState) {
        const agent = this.selectedAgent();
        if(this.state.activeAgent !== agent) {
            this.setAgent(agent);
            this.setCenter(agent);
        }
        if(this.subOptionsElem) {
            if(this.state.expanded && !this.isSelected(this.props.option)) {
                this.setState({ expanded: false });
            }
            setTimeout(() => {
                if(this.subOptionsElem) {
                    const height = this.subOptionsElem.offsetHeight;
                    if(height !== this.height) {
                        this.height = height;
                        this.forceUpdate();
                    }
                }
            });
        }
    }

    public render() {
        const option = this.props.option;
        let optionClassName = '';
        if(this.isSelected(option)) {
            optionClassName = optionClassName + ' unifaun-checkout-selected' + this.props.level;
        }
        if(this.hasSubOptions(option)) {
            optionClassName = optionClassName + ' unifaun-checkout-multilevel' + this.props.level;
        }
        return (
            <div className={ 'unifaun-checkout-option' + this.props.level + optionClassName } onClick={ this.onOptionClick }>
                <div className={ 'unifaun-checkout-option-header' + this.props.level }>
                    <div className="unifaun-checkout-option-radio">
                        <input
                            id={ 'UnifaunCheckoutOption' + option.id }
                            type="radio"
                            name={ 'UnifaunCheckoutOptionLvl' + this.props.level + '$' + this.props.parentId }
                            value={ option.id }
                            disabled={ this.props.disabled }
                            checked={ this.isSelected(option) }
                            onClick={ this.onClickNoBubble }
                            onChange={ this.onOptionChange }/>
                        <span className="unifaun-checkout-option-radio-target"></span>
                    </div>
                    { this.renderPrefixIcon(option) }
                    <div className="unifaun-checkout-option-columns">
                        <div className="unifaun-checkout-option-column1">
                            <div className="unifaun-checkout-option-column1-text">
                                <label
                                    htmlFor={ 'UnifaunCheckoutOption' + option.id }
                                    className="unifaun-checkout-option-title"
                                    onClick={ this.onClickNoBubble }>
                                    { option.name }
                                </label>
                                <br/>
                                <span className="unifaun-checkout-option-delivery">{ option.description1 }</span>
                                <span className="unifaun-checkout-option-separator">{ option.description1 && option.description2 ? <br/> : '' }</span>
                                <span className="unifaun-checkout-option-description1">{ option.description2 }</span>
                            </div>
                            { this.renderSuffixIcon(option) }
                        </div>
                        <div className="unifaun-checkout-option-column2">
                            <span className="unifaun-checkout-option-description2">{ option.description3 }</span>
                            { option.description3 && option.description4 ? <br /> : null }
                            <span className="unifaun-checkout-option-description3">{ option.description4 }</span>
                            { option.description3 || option.description4 ? <br /> : null }
                            { this.renderAgents(option) }
                            <div className="unifaun-checkout-option-map-container">
                                { this.renderMap(option) }
                                { this.props.cssStyle === 'unifaun-mp' && this.isSelected(option) ? this.renderAgentPanel(option) : null}
                            </div>
                        </div>
                    </div>
                    <div className="unifaun-checkout-option-price">
                        { this.renderPrice(option) }
                    </div>
                </div>
                <ReactMotion.Motion defaultStyle={ { height: 0 } } style={ this.subOptionMotionStyle(option) } onRest={ this.onMotionDone }>
                    {
                        (style: { height: number }) => {
                            return (
                                <div
                                    className="unifaun-checkout-clip-box"
                                    style={this.getClipBoxStyle(option, style) }>
                                    { this.renderSubOptions(option) }
                                </div>
                            );
                        }
                    }
                </ReactMotion.Motion>
            </div>
        );
    }

    private renderMap(option: Model.Option) {
        if (this.props.enableMap && (!this.props.disabled && this.isSelected(option) && option.agents && option.agents.length > 0)) {
            return (
                <LeafletMap
                    center={this.state.mapCenter}
                    option={option}
                    activeAgent={this.state.activeAgent}
                    onMapAgentChange={this.onMapAgentChange}
                    onToggleInfo={this.onToggleInfo}
                    setAgent={this.setAgent}
                    setCenter={this.setCenter}
                />
            );
        } else {
            return null;
        }
    }

    private setAgent = (agent?: Model.Agent) => {
        this.setState({activeAgent: agent});
    }

    private setCenter = (agent?: Model.Agent) => {
        if (agent && agent.mapLatitude && agent.mapLongitude) {
            this.setState({mapCenter: {lat: agent.mapLatitude, lng: agent.mapLongitude}});
        }
    }

    private onMapAgentChange = (agentId: string) => {
        const option = this.props.option;
        if(option.agents) {
            for(const agent of option.agents) {
                if(agent.id === agentId) {
                    this.props.onAgentSelect(option, agent);
                    this.setAgent(agent);
                    break;
                }
            }
        }
    }

    private getClipBoxStyle(option: Model.Option, style: { height: number }) {
        if(this.isSelected(option) && ((this.props.level > this.props.animLevel) || this.state.expanded)) {
            return { height: 'auto' };
        } else {
            return style;
        }
    }

    private onMotionDone = () => {
        this.setState({ expanded: this.isSelected(this.props.option) });
        if(this.subOptionsElem) {
            this.height = this.subOptionsElem.offsetHeight;
            this.forceUpdate();
        }
    }

    private subOptionMotionStyle(option: Model.Option): { height: ReactMotion.OpaqueConfig | number } {
        if(this.subOptionsElem && this.isSelected(option)) {
            return { height: (this.props.level <= this.props.animLevel ? ReactMotion.spring(this.height, MOTION_CONFIG) : this.height) };
        } else {
            return { height: MOTION_ZERO_SPRING };
        }
    }

    private renderPrefixIcon(option: Model.Option) {
        if(!this.props.ultraNarrow && this.props.iconsInFront && this.props.displayIcon && option.carrierId) {
            return (
                <div className="unifaun-checkout-option-prefix-icon">
                    <LogoImage url={ this.props.iconsBaseUrl + option.carrierId.toLowerCase() + '.png' }/>
                </div>
            );
        } else {
            return <div className="unifaun-checkout-option-no-icons-spacer"></div>;
        }
    }

    private renderSuffixIcon(option: Model.Option) {
        if(!this.props.ultraNarrow && !this.props.iconsInFront && this.props.displayIcon && option.carrierId) {
            return (
                <div className="unifaun-checkout-option-suffix-icon">
                    <LogoImage url={ this.props.iconsBaseUrl + option.carrierId.toLowerCase() + '.png' }/>
                </div>
            );
        } else {
            return null;
        }
    }
    private renderPrice(option: Model.Option) {
        if(option.subOptions && (option.subOptions.length > 0)) {
            let minPrice = Number.MAX_VALUE;
            let minPriceDescr = '';
            let maxPrice = -Number.MAX_VALUE;
            let maxPriceDescr = '';
            option.subOptions.forEach((subOption) => {
                if(subOption.priceValue < minPrice) {
                    minPrice = subOption.priceValue;
                    minPriceDescr = subOption.priceDescription;
                }
                if(subOption.priceValue > maxPrice) {
                    maxPrice = subOption.priceValue;
                    maxPriceDescr = subOption.priceDescription;
                }
            });
            if(minPriceDescr === maxPriceDescr) {
                return (<span className="unifaun-checkout-option-price-description">{ maxPriceDescr }</span>);
            } else {
                return (
                    <span>
                        <span className="unifaun-checkout-option-price-description">{ minPriceDescr }</span>
                        <span className="unifaun-checkout-option-price-separator"> - </span>
                        <span className="unifaun-checkout-option-price-description">{ maxPriceDescr }</span>
                    </span>
                );
            }
        } else {
            return (<span className="unifaun-checkout-option-price-description">{ option.priceDescription }</span>);
        }
    }

    private renderAgents(option: Model.Option) {
        if(option.agents && (option.agents.length > 0)) {
            const validation = this.props.validationResult.agents[this.props.option.id];
            let className = 'unifaun-checkout-option-agents';
            if (validation) {
                className += ' unifaun-checkout-invalid';
            }
            return (
                <div className="unifaun-checkout-option-agents-wrapper">
                    {
                        /*
                        validation && (
                            <span className="unifaun-checkout-text-input-label-message">{validation.message}</span>
                        )
                        */
                    }
                    <select
                        className={className}
                        disabled={ this.props.disabled || !this.isSelected(option) }
                        value={ this.selectedAgentId() }
                        onChange={ this.onAgentChange }>
                        { this.renderAgentItems(option.agents, option.noDefaultAgent) }
                    </select>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderAgentItems(agents: Model.Agent[], noDefaultAgent?: boolean) {
        const options = agents.map((agent) => {
            return (
                <option key={ agent.id } value={ agent.id }>{ agent.name }
                 { agent.address1 ? ', ' + agent.address1 : ''}{ agent.city ? ', ' + agent.city : '' }{ agent.state ? ', ' + agent.state : '' }</option>
            );
        });

        if (noDefaultAgent && !this.selectedAgentId()) {
            options.unshift(<option key="empty" value="">{TranslatedTexts.texts.chooseLabel}</option>);
        }
        return options;
    }

    private renderSubOptions(option: Model.Option) {
        if(this.hasSubOptions(option) || this.hasAddons(option) || this.hasFields(option) || this.hasAgents(option)) {
            let className = '';
            if(!((this.props.level <= this.props.animLevel) || this.isSelected(option))) {
                className = ' unifaun-checkout-hidden';
            }
            return (
                <div ref={ (elem) => { this.subOptionsElem = elem; }} className={ 'unifaun-checkout-option-sub-options' + className }>
                    { this.renderAddonFieldsAndAgents(option) }
                    { this.renderSubOptionItems(option.subOptions) }
                </div>
            );
        } else {
            return null;
        }
    }

    private renderAddonFieldsAndAgents(option: Model.Option) {
        if((option.addons && (option.addons.length > 0)) || (option.fields && (option.fields.length > 0)) || (option.agents && (option.agents.length > 0))) {
            return this.renderAddonFieldsAndAgentPanels(option);
        } else {
            return null;
        }
    }

    private renderAddonFieldsAndAgentPanels(option: Model.Option) {
        if(this.props.narrow) {
            return (
                <div className="unifaun-checkout-option-sub-columns">
                    <div key="column2" className="unifaun-checkout-option-sub-column2">
                        { this.props.cssStyle !== 'unifaun-mp' ? this.renderAgentPanel(option) : null}
                    </div>
                    { this.renderSubPanelSpacer(option) }
                    <div key="column1" className="unifaun-checkout-option-sub-column1">
                        { this.renderAddonFieldsPanel(option) }
                    </div>
                </div>
            );
        } else {
            return (
                <div className="unifaun-checkout-option-sub-columns">
                    <div key="column1" className="unifaun-checkout-option-sub-column1">
                        { this.renderAddonFieldsPanel(option) }
                    </div>
                    { this.renderSubPanelSpacer(option) }
                    <div key="column2" className="unifaun-checkout-option-sub-column2">
                        { this.props.cssStyle !== 'unifaun-mp' ? this.renderAgentPanel(option) : null}
                    </div>
                </div>
            );
        }
    }

    private renderSubPanelSpacer(option: Model.Option) {
        if(this.props.iconsInFront && this.props.displayIcon && option.carrierId) {
            return (<div className="unifaun-checkout-option-sub-column-icon-spacer"></div>);
        } else {
            return null;
        }
    }

    private renderSubOptionItems(options: Model.Option[] | undefined): JSX.Element[] | null {
        if(options) {
            const useIcon = this.props.useIcons && (options.filter((option) => !!option.carrierId).length > 0);
            return options.map((option) => {
                return (
                    <Option
                        key={ option.id }
                        option={ option }
                        level={ this.props.level + 1 }
                        animLevel={ this.props.animLevel }
                        parentId={ this.props.option.id }
                        selectedOptionIds={ this.props.selectedOptionIds }
                        selectedAgentIds={ this.props.selectedAgentIds }
                        narrow={ this.props.narrow }
                        ultraNarrow={ this.props.ultraNarrow }
                        addonValues={ this.props.addonValues }
                        fieldValues={ this.props.fieldValues }
                        displayIcon={ useIcon }
                        useIcons={ this.props.useIcons }
                        iconsInFront={ this.props.iconsInFront }
                        iconsBaseUrl={ this.props.iconsBaseUrl }
                        disabled={ this.props.disabled || !this.isSelected(this.props.option) }
                        hideAgentInfo={ this.props.hideAgentInfo }
                        addonDisabled={ this.props.addonDisabled }
                        validationResult={ this.props.validationResult }
                        enableMap={this.props.enableMap}
                        cssStyle={this.props.cssStyle}
                        onOptionSelect={ this.props.onOptionSelect }
                        onAgentSelect={ this.props.onAgentSelect }
                        onAnimLevel={ this.props.onAnimLevel }
                        onAddonValueChange={ this.props.onAddonValueChange }
                        onFieldValueChange={ this.props.onFieldValueChange }/>
                );
            });
        } else {
            return null;
        }
    }

    private renderAddonFieldsPanel(option: Model.Option) {
        if((option.addons && (option.addons.length > 0)) || (option.fields && (option.fields.length > 0))) {
            return (
                <div className="unifaun-checkout-option-fields-panel">
                    <Addons
                        option={option}
                        addons={option.addons}
                        disabled={this.props.disabled}
                        addonDisabled={this.props.addonDisabled}
                        validationResult={this.props.validationResult}
                        addonValues={this.props.addonValues}
                        level={this.props.level}
                        selectedOptionIds={this.props.selectedOptionIds}
                        onAddonValueChange={this.props.onAddonValueChange}
                        theme={this.props.cssStyle}
                    />
                    {/* { this.renderFieldsLabel(option.fields) } */}
                    { this.renderFields(option, option.fields) }
                </div>
            );
        } else {
            return null;
        }
    }

    private renderFields(option: Model.Option, fields: Model.Field[] | undefined) {
        if(!fields) {
            return null;
        }
        let validationResults: Model.ValidationFieldResultMap | undefined = undefined;
        if(this.isSelected(option) && this.props.validationResult && this.props.validationResult.fields) {
            validationResults = this.props.validationResult.fields;
        }
        return fields.map((field) => {
            const value = this.props.fieldValues[field.id];
            let validationResult: Model.ValidationFieldResult | undefined = undefined;
            if(validationResults) {
                validationResult = validationResults[field.id];
            }
            return (
                <Field
                    key={ option.id + '$' + field.id + '$Field' }
                    option={ option }
                    field={ field }
                    value={ value }
                    disabled={ this.props.disabled || !this.isSelected(option) }
                    active={ this.isSelected(option) }
                    validationResult={ validationResult }
                    onFieldValueChange={ this.props.onFieldValueChange }
                    theme={ this.props.cssStyle }/>
            );
        });
    }

    private renderAgentPanel(option: Model.Option) {
        const agent = this.selectedAgent();
        if(!this.props.hideAgentInfo && option.agents && (option.agents.length > 0) && agent) {
            return (
                <div className="unifaun-checkout-option-agent-panel">
                    { this.isSelected(option)
                        ? <a href="" onClick={ this.onToggleInfo } className="unifaun-checkout-option-agent-info-onoff-link">
                            <span className="unifaun-checkout-option-agent-info-onoff">
                                { this.state.showInfo ? '-' : '+' }
                            </span> { TranslatedTexts.texts.agentInfoLabel }
                          </a>
                        : <span>{ TranslatedTexts.texts.agentInfoLabel }</span> }
                    <ReactMotion.Motion defaultStyle={ { height: 0 } } style={ this.agentInfoStyles() } onRest={ this.onAgentMotionDone }>
                        {
                            (style: { height: number }) => {
                                return (
                                    <div className="unifaun-checkout-clip-box" style={ style }>
                                        { this.renderAgentInfo(option, agent) }
                                    </div>
                                );
                            }
                        }
                    </ReactMotion.Motion>
                </div>
            );
        } else {
            return null;
        }
    }

    private renderHours(index1: number, index2: number, description: string | undefined, start: string, stop: string) {
        return (
            <tr key={`${index1}${index2}`}>
                <td>{ index2 === 0 ? description : '' }</td>
                <td>{ start } - { stop }</td>
            </tr>
        );
    }

    private renderClosed(index1: string, description: string | undefined) {
        return (
            <tr key={`${index1}`}>
                <td>{ description }</td>
                <td>{ TranslatedTexts.texts.closed }</td>
            </tr>
        );
    }

    private renderOpeningHours(openingHourWeekday?: Model.OpeningHourWeekday[], openingHourSpecialDay?: Model.OpeningHourSpecialDay[]): JSX.Element | any {
        let rows: any[] = []; // JSX.Element complains about no root element.

        if (openingHourWeekday && openingHourWeekday.length > 0) {
            rows = rows.concat(openingHourWeekday.map((weekday, index1) => {
                    if (weekday.closed) {
                        return this.renderClosed('' + index1, weekday.description);
                    } else {
                        return weekday.hours && weekday.hours.map(({ start, stop }, index2) => {
                            return this.renderHours(index1, index2, weekday.description, start, stop);
                        });
                    }
                })
            );
        }

        if (openingHourSpecialDay && openingHourSpecialDay.length > 0) {
            // Does "date" need momentjs?
            rows = rows.concat(openingHourSpecialDay.map(({ date, closed, hours }, index1) => {
                if (closed) {
                    return this.renderClosed(date + index1, date);
                }

                return hours.map(({ start, stop }, index2) => (
                    <tr key={`${date}${index1}${index2}`}>
                        <td>{ date }</td>
                        <td>{ start } - { stop }</td>
                        <td></td>
                    </tr>
                ));
            }));
        }

        return rows.length > 0 ? (
            <table className="unifaun-checkout-option-agent-opening-hours">
                <tbody>
                    <tr>
                        <td colSpan={3}>{ TranslatedTexts.texts.openingHours }</td>
                    </tr>
                    { rows }
                </tbody>
            </table>
        ) : null;
    }

    private renderAgentInfo(option: Model.Option, agent: Model.Agent) {
        return (
            <div ref={ (elem) => { this.agentInfoElem = elem; } } className="unifaun-checkout-option-agent-info">
                { agent.name }
                { agent.name ? <br/> : null }
                { agent.address1 }
                { agent.address1 ? <br/> : null }
                { agent.address2 }
                { agent.address2 ? <br/> : null }
                { agent.state ? agent.state + ' ' : null }{ agent.zipCode } { agent.city }
                { this.renderOpeningHours(agent.openingHourWeekdays, agent.openingHourSpecialDays) }
                { agent.additionalInfo ? <br/> : null}
                { agent.additionalInfo }
            </div>
        );
    }

    private onAgentMotionDone = () => {
        if(this.subOptionsElem) {
            this.height = this.subOptionsElem.offsetHeight;
            this.forceUpdate();
        }
    }

    private agentInfoStyles(): { height: ReactMotion.OpaqueConfig | number } {
        if(this.agentInfoElem && this.state.showInfo) {
            return {
                height: this.getAgentInfoStylesHeight()
            };
        } else {
            return { height: MOTION_ZERO_SPRING };
        }
    }

    private getAgentInfoStylesHeight(): number | ReactMotion.OpaqueConfig {
        if(!this.agentInfoElem) {
            return 0;
        }
        if(this.props.level <= this.props.animLevel) {
            return ReactMotion.spring(this.agentInfoElem.offsetHeight, MOTION_CONFIG);
        } else {
            return this.agentInfoElem.offsetHeight;
        }
    }

    private isSelected(option: Model.Option): boolean {
        return !!this.props.selectedOptionIds && (option.id === this.props.selectedOptionIds[Model.levelKey(this.props.level)]);
    }

    private hasSubOptions(option: Model.Option): boolean {
        return !!option.subOptions && (option.subOptions.length > 0);
    }

    private hasAddons(option: Model.Option): boolean {
        return !!option.addons && (option.addons.length > 0);
    }

    private hasFields(option: Model.Option): boolean {
        return !!option.fields && (option.fields.length > 0);
    }

    private hasAgents(option: Model.Option): boolean {
        return !!option.agents && (option.agents.length > 0);
    }

    private selectedAgentId(): string {
        return this.props.selectedAgentIds ? this.props.selectedAgentIds[this.props.option.id] : '';
    }

    private selectedAgent(): Model.Agent | undefined {
        const agentId = this.selectedAgentId();
        const option = this.props.option;
        if(option.agents && agentId) {
            for(const agent of option.agents) {
                if(agent.id === agentId) {
                    return agent;
                }
            }
        }
        return undefined;
    }

    private onOptionClick = (event: React.SyntheticEvent<any>) => {
        event.stopPropagation();
        if (this.isSelected(this.props.option)) {
            return;
        }
        this.props.onOptionSelect(this.props.level, this.props.parentId, this.props.option);
        this.measureSubOptions();
    }

    private onOptionChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        event.stopPropagation();
        if(event.currentTarget.checked) {
            this.props.onOptionSelect(this.props.level, this.props.parentId, this.props.option);
        }
        this.measureSubOptions();
    }

    private onClickNoBubble = (event: React.SyntheticEvent<any>) => {
        event.stopPropagation();
    }

    private onAgentChange = (event: React.FormEvent<HTMLSelectElement>) => {
        event.stopPropagation();
        const agentId = event.currentTarget.value;
        const option = this.props.option;
        if(option.agents) {
            for(const agent of option.agents) {
                if(agent.id === agentId) {
                    this.setAgent(agent);
                    this.setCenter(agent);
                    this.props.onAgentSelect(option, agent);
                    this.setState({ showInfo: false, expanded: true });
                    break;
                }
            }
        }
    }

    private onToggleInfo = (event: React.SyntheticEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.onAnimLevel(this.props.level);
        this.setState({ showInfo: !this.state.showInfo, expanded: true });
        this.measureSubOptions();
    }

    private measureSubOptions() {
        if(this.subOptionsElem) {
            setTimeout(() => {
                if(this.subOptionsElem) {
                    this.height = this.subOptionsElem.offsetHeight;
                    this.forceUpdate();
                }
            });
        }
    }
}
